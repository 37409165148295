import { Button, Col, Input, Modal, Row, Tag, message } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { http } from "../../../../helpers/http";

export const LessonSlot = (props: any) => {
    const { slot, index, setSlot, setModalVisible, updateTimeSlots, started } = props;

    const startEvaluation = () => {
        console.log(slot)
        setSlot(slot);
        setModalVisible(true);
    };

    const removeStudent = () => {
        let cancelComment = "";
        Modal.confirm({
            title: "Atcelt studenta nodarbību",
            content: <div>
                <p>Lūdzu ievadiet iemeslu, kāpēc vēlaties atcelt studenta nodarbību:</p>
                <Input.TextArea
                    // value={cancelComment}
                    onChange={(e) => cancelComment = e.target.value}
                    style={{ width: "100%" }}
                />
            </div>,
            onOk() {
                if (!cancelComment) {
                    message.error("Lūdzu ievadiet iemeslu, kāpēc vēlaties atcelt studenta nodarbību");
                    return;
                }
                http.post(`time_slots/${slot.timeslot_id}/remove_student`).then((response) => {
                    updateTimeSlots();
                }).catch((e: any) => {
                    console.error("error in remove_student", e);
                });
            },
        })
        // Modal.confirm({
        //     title: "Atcelt studenta nodarbību",
        //     content: "Apstiprinot, studenta nodarbība tiks atcelta!",
        //     onOk() {
        //         http.post(`time_slots/${slot.timeslot_id}/remove_student`).then((response) => {
        //             updateTimeSlots();
        //         }).catch((e: any) => {
        //             console.error("error in remove_student", e);
        //         });
        //     },
        // })
    };

    return <li className="day-slot">
        <Row align="middle" gutter={16} justify="space-between">
            <Col>
                <Row gutter={16}>
                    <Col>
                        {slot?.lesson_date} / {slot?.time_from} {slot?.time_to}
                    </Col>
                    <Col>
                        {slot?.meeting_place}
                    </Col>
                </Row>
            </Col>
            {
                (started && !(slot?.student_status === "done") && !(slot?.student_status === "exam_failed") && !(slot?.student_status === "exam_passed"))
                && <Tag color="red">Uzsākta vērtēšana</Tag>
            }
            <Col>
                {
                    slot?.evaluate_allowed === 1
                        && (moment(slot?.lesson_date, "YYYY-MM-DD").isBefore(moment(), "day")
                            || (
                                moment(slot?.lesson_date, "YYYY-MM-DD").isSame(moment(), "day")
                                && moment(slot?.time_from, "HH:mm").subtract(15, "minutes").isSameOrBefore(moment(), "minutes")
                            ))
                        ? <Row>
                            <Button
                                className="btn-black"
                                style={{ marginRight: 10 }}
                                type="primary"
                                onClick={startEvaluation}
                            >
                                Sākt vērtēšanu
                            </Button>
                            {
                                (moment(slot?.lesson_date, "YYYY-MM-DD").isSameOrAfter(moment(), "date") && (!!slot?.allow_bought_slot_del || window.sessionStorage.getItem('admin')))
                                && <Button
                                    danger
                                    onClick={removeStudent}
                                >
                                    Atcelt
                                </Button>
                            }
                        </Row>
                        : <></>
                }
            </Col>
        </Row>
    </li>
}