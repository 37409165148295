import axios from "axios";

const localhost = "https://api.einsteins.lv/instructor-api/";
const instructorApiBaseUrl = process.env.REACT_APP_INSTRUCTOR_API_BASE_URL
  ? process.env.REACT_APP_INSTRUCTOR_API_BASE_URL
  : localhost

const http = axios.create({
  baseURL: instructorApiBaseUrl,
  headers: {
    "Accept": "application/json"
  }
});

window["http" as any] = http as any;

export { http };