import { Card } from "antd";
import React from "react";
import { PointInput } from "./PointInput";

export const FixedPointsForm = (props: any) => {
    const {
        points,
        removePoint,
        updatePoint
    } = props;

    return <Card
        className="grey-title-card"
        style={{ margin: 20 }}
        title={<b style={{ color: "#FFFFFF" }}>Fiksētie punkti kartē</b>}
    >
        {
            points.map((point: any, index: any) => {
                return <PointInput
                    key={index}
                    point={point}
                    removeMapPoint={(ind: any) => removePoint(ind)}
                    updatePoint={(ind: any, data: string) => updatePoint(ind, data)}
                    index={index}
                />
            })
        }
    </Card>
}