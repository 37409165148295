import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const ExchangedReminder = () => {

    return <div style={
        {
            display: "flex",
            alignItems: "center",
            border: "1px solid red",
            backgroundColor: "salmon",
            padding: "20px 20px 20px 60px",
            position: "fixed",
            zIndex: 2,
            width: "100%",
            fontWeight: "bold",
            top: 0,
            marginLeft: window.matchMedia("(max-width: 767px)").matches ? 0 : 200
        }
    }>
        Uzmanību! Šobrīd jums ir uzstādīts maiņas auto!
        <Link to="/exchange-cars">
            <Button
                style={{ marginLeft: 10 }}
                type="primary"
            >
                Mainīt
            </Button>
        </Link>
    </div>
}