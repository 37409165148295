import { Card, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { LessonsList } from "./LessonsList";
import "../../../css/DayLessons.scss";
import { http } from "../../../helpers/http";

const Title = () => {
    return <Row justify="space-between">
        <b>Šīs dienas nodarbības</b>
        <b>{moment().format("DD. MMM HH:mm")}</b>
    </Row>
}

export const DayLessonsCard = (props: any) => {
    const { todayLessons, updateTimeSlots } = props;
    const [slots, setSlots] = useState({} as any);

    const formatDataByMeetingPlace = (lessons: any) => {
        let newDataObj = {} as any;
        lessons.forEach((lesson: any) => {
            const meetingPlace = lesson.meeting_places.find((el: any) => el.city_id === lesson.city_id)?.[0]
            if (newDataObj[meetingPlace]) {
                newDataObj[meetingPlace] = [
                    ...newDataObj[meetingPlace],
                    lesson
                ]
                return;
            }
            newDataObj[meetingPlace] = [lesson]

        })
        console.log(newDataObj)
        setSlots(newDataObj);
    }

    useEffect(() => {
        if (todayLessons.length > 0) {
            formatDataByMeetingPlace(todayLessons);
        }
        console.log(todayLessons)
    }, [todayLessons])

    return <Card
        title={<Title />}
        style={{ marginBottom: 40 }}
    >
        {
            Object.keys(slots)?.map((key: any, index: any) => {
                return <LessonsList updateTimeSlots={updateTimeSlots} key={index} slots={slots[key]} meetingPlace={key} />
            })
        }
    </Card>
}