import { Button, Col, Input, Modal, Row, Tag, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { http } from "../../../helpers/http";
import { StartEvaluationModal } from "../../StudentStats/components/StartEvaluationModal";
import { AddStudentModal } from "./AddStudentModal";

export const LessonSlot = (props: any) => {
    const history = useHistory();
    const { slot, updateTimeSlots, started } = props;
    const [visible, setVisible] = useState(false);
    const [addStudentVisible, setAddStudentVisible] = useState(false);
    // const [cancelComment, setCancelComment] = useState("")

    const drivingOptions = {
        "city": "Satiksme",
        "field": "Laukums"
    } as any

    const removeStudent = () => {
        let cancelComment = "";
        Modal.confirm({
            title: "Atcelt studenta nodarbību",
            content: <div>
                <p>Lūdzu ievadiet iemeslu, kāpēc vēlaties atcelt studenta nodarbību:</p>
                <Input.TextArea
                    // value={cancelComment}
                    onChange={(e) => cancelComment = e.target.value}
                    style={{ width: "100%" }}
                />
            </div>,
            onOk() {
                if (!cancelComment) {
                    message.error("Lūdzu ievadiet iemeslu, kāpēc vēlaties atcelt studenta nodarbību");
                    return;
                }
                http.post(`time_slots/${slot.slot_id}/remove_student`, {
                    instructor_cancel_comment: cancelComment
                }).then((response) => {
                    updateTimeSlots();
                }).catch((e: any) => {
                    console.error("error in remove_student", e);
                });
            },
        })
    };

    return slot?.slot_status?.includes("student") || slot?.slot_status?.includes("instructor") || slot?.student_status?.includes("student")
        ? <></>
        : <li className="day-slot">
            <Row align="middle" gutter={16} justify="space-between">
                <Col>
                    <Row gutter={16} align="middle">
                        <Col>
                            {slot?.time_from} {slot?.time_to} <b>{slot?.driving_option ? drivingOptions[slot?.driving_option] : ""}</b>
                        </Col>
                        <Col>
                            {
                                slot?.student_name
                                    ? <Button
                                        type="text"
                                        onClick={() => history.push(`/student-stats/${slot?.student_id}`)}
                                    >
                                        {slot?.student_name}
                                    </Button>
                                    : "Nav pieteicies neviens students"
                            }
                        </Col>
                        {
                            slot?.student_status === "exam"
                            && <Tag color="purple">Eksāmens</Tag>
                        }
                        {
                            (started && !(slot?.student_status === "done") && !(slot?.student_status === "exam_failed") && !(slot?.student_status === "exam_passed"))
                            && <Tag color="red">Uzsākta vērtēšana</Tag>
                        }
                    </Row>
                </Col>
                <Col>
                    {
                        slot?.student_name
                            ? slot?.student_status === "done" || slot?.student_status === "exam_passed" || slot?.student_status === "exam_failed"
                                ? <Tag color="green">Nodarbība pabeigta</Tag>
                                : moment(slot?.date, "YYYY-MM-DD").isSame(moment(), "day") && moment(slot?.time_from, "HH:mm:ss").subtract(15, "minutes").isSameOrBefore(moment(), "minute")
                                    ? <div>
                                        {
                                            (!!slot?.allow_bought_slot_del || window.sessionStorage.getItem('admin'))
                                            && <Button
                                                danger
                                                onClick={removeStudent}
                                            >
                                                Atcelt
                                            </Button>
                                        }
                                        {
                                            slot?.full_eval
                                                ? <Button
                                                    className="btn-black"
                                                    style={{ marginLeft: 5 }}
                                                    type="primary"
                                                    onClick={() => setVisible(true)}
                                                >
                                                    Sākt vērtēšanu
                                                </Button>
                                                : <Button
                                                    className="btn-black"
                                                    style={{ marginLeft: 5 }}
                                                    type="primary"
                                                    onClick={() => setVisible(true)}
                                                >
                                                    Vērtēt
                                                </Button>
                                        }

                                    </div>
                                    : <></>
                            : slot?.how_many_lessons === 5
                                ? <b>Eksāmens</b>
                                : moment(slot?.date, "YYYY-MM-DD").isSame(moment(), "day") && moment(slot?.time_from, "HH:mm:ss").isSameOrAfter(moment(), "minute")
                                    ? <Button
                                        type="text"
                                        onClick={() => setAddStudentVisible(true)}
                                    >
                                        Pievienot studentu
                                    </Button>
                                    : <></>
                    }
                    {
                        <AddStudentModal
                            modalVisible={addStudentVisible}
                            setModalVisible={setAddStudentVisible}
                            slot={slot}
                            updateTimeSlots={updateTimeSlots}
                        />
                    }
                    {
                        visible
                        && <StartEvaluationModal
                            isVisible={visible}
                            setIsVisible={setVisible}
                            slot={slot}
                            updateTimeSlots={updateTimeSlots}
                            exam={slot?.student_status === "exam"}
                            started={started}
                        />
                    }
                </Col>
            </Row>
        </li>
}