import React, { useEffect, useState } from "react";
import { NotApprovedSlot } from "./NotAprrovedSlot";

export const NotApprovedSlotList = (props: any) => {
    const { slots, updateTimeSlots } = props;
    const [startedSlots, setStartedSlots] = useState([] as any);

    const checkIfStartedEval = () => {
        const statsStr = localStorage.getItem("stats");
        if (statsStr) {
            const stats = JSON.parse(statsStr);
            setStartedSlots(stats)
        }
    }

    useEffect(() => {
        checkIfStartedEval();
    }, [])

    return <div style={{ marginBottom: 40 }}>
        <ul
            className="day-slots-list"
            style={{
                padding: 0,
                margin: 0,
            }}
        >
            {
                slots?.map((slot: any, index: any) => {
                    const started = startedSlots.some((item: any) => Number(item.lesson_id) === slot.student_lesson_id)
                    return <NotApprovedSlot
                        started={started}
                        key={index}
                        slot={slot}
                        updateTimeSlots={updateTimeSlots}
                    />
                })
            }
        </ul>
    </div>
}