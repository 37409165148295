import { Button, Table } from "antd";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

export const QuestionHistory = (props: any) => {
    const history = useHistory();
    const { id } = useParams() as any;
    const { slots, questionID } = props;

    const difficultyTranslation = {
        beginner: "Viegls",
        medium: "Vidējs",
        exam_worthy: "Tuvojas eks."
    } as any;

    const gradeTranslation = {
        beginner: "Nav pietiekami",
        medium: "Pietiekami",
        exam_worthy: "Labi"
    } as any;

    const columns = [
        {
            title: "Datums un laiks",
            key: "datetime",
            render: (nothing: any, record: any) => {
                return `${record.lesson_date} ${record.time_from} ${record.time_to}`
            }
        },
        {
            title: "Instruktors",
            dataIndex: "instructor_name",
            key: "instructor_name"
        },
        {
            title: "Nodarbības līmenis",
            dataIndex: "grade",
            key: "grade",
            render: (grade: any, record: any) => {
                return difficultyTranslation[grade];
            }
        },
        {
            title: "Vērtējums",
            key: "rating",
            render: (nothing: any, record: any) => {
                if (Array.isArray(record.grades)) return false;
                const beginner = record.grades.beginner?.subject_ids?.some((el: any) => el === questionID)
                const medium = record.grades.medium?.subject_ids?.some((el: any) => el === questionID)
                const exam_worthy = record.grades.exam_worthy?.subject_ids?.some((el: any) => el === questionID)
                const level = beginner
                    ? "beginner"
                    : medium
                        ? "medium"
                        : exam_worthy
                            ? "exam_worthy"
                            : ""
                return gradeTranslation[level];
            }
        },
        {
            title: "",
            key: "action",
            render: (nothing: any, record: any) => {
                return <Button
                    onClick={() => history.push(`/student-stats/${id}/lesson-overview/${record.student_lesson_id}`)}
                >
                    Atvērt nodarbību
                </Button>
            }
        },
    ] as any;

    useEffect(() => {
        console.log(slots, questionID)
    }, [slots])

    return <Table
        dataSource={slots}
        columns={columns}
    />
}