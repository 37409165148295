import { Button, Card, Col, Descriptions, message, Row, Table } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { http } from "../../helpers/http";
import { AxiosResponse } from 'axios';
import moment from "moment";
import { ColumnsType } from "antd/lib/table";
import { useParams } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';

type Invoice = {
    id: number;
    instructor: string;
    instructor_requisites: string;
    invoice_date: string;
    invoice_period: string;
    student_invoice_number: string;
    total: number;
    total_vat: number;
    student_requisites: any;
    student: string;
    positions: any;
}

export const Invoice = () => {
    const { id, studentID }: { id: string | undefined, studentID: string | undefined } = useParams();
    const printable = useRef(null);
    const printHandle = useReactToPrint({
        content: () => printable.current
    })
    const [invoice, setInvoice] = useState<Invoice | null>(null);
    const [loading, setLoading] = useState(false);

    const fetchInvoice = () => {
        setLoading(true);
        http.get(`invoices/get-student-invoice/${studentID}/${id}`).then((res: AxiosResponse) => {
            console.log(res)
            const { data } = res;
            const positions = Object.keys(data.positions).map((key: any) => {
                return data.positions[key]
            }).reduce((prev: any, current: any) => {
                return [...prev, ...current]
            }, []);
            positions.push({
                key: "sum_without_vat",
                rate: "Summa bez PVN",
                total_rate: data.total
            });
            positions.push({
                key: "vat",
                rate: "PVN 21%",
                total_rate: data.total_vat - data.total
            });
            positions.push({
                key: "sum_with_vat",
                rate: "Apmaksātā summa",
                total_rate: data.total_vat
            });
            setInvoice({
                id: data.id,
                instructor: data.instructor,
                instructor_requisites: data.instructor_requisites,
                invoice_date: data.invoice_date,
                invoice_period: data.invoice_period,
                student_invoice_number: data.student_invoice_number,
                total: data.total,
                total_vat: data.total_vat,
                student_requisites: data.student_requisites,
                student: data.student,
                positions
            })
            setLoading(false);
        }).catch(() => {
            message.error("Kļūda!");
            setLoading(false);
        })
    }

    useEffect(() => {
        fetchInvoice();
    }, [])

    return <>
        <Row justify="start">
            <Button
                onClick={printHandle}
                style={{ marginBottom: 10 }}
            >
                <PrinterOutlined style={{ fontSize: 20 }} />
            </Button>
        </Row>
        <div ref={printable} className={`printable`} style={{ minWidth: 1000 }}>
            <Card style={{ marginBottom: 10 }} loading={loading}>
                <Row gutter={20}>
                    <Col span={12}>
                        <Descriptions bordered size="small">
                            <Descriptions.Item span={3} label={<strong>RĒĶINS Nr.</strong>}>
                                <strong>{invoice ? invoice.student_invoice_number : ""}</strong>
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label="Datums">
                                {invoice ? moment(invoice?.invoice_date, "YYYY-MM-DD").format("DD.MM.YYYY") : ""}
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label="Apmaksas veids">
                                Pārskaitījums
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={12}>
                        <Descriptions bordered size="small">
                            <Descriptions.Item span={3} label={<strong>APMAKSAS SUMMA (EUR)</strong>}>
                                <strong>{invoice ? invoice.total_vat.toFixed(2) : ""}</strong>
                            </Descriptions.Item>
                            {/* <Descriptions.Item span={3} label={<strong>APMAKSAS TERMIŅŠ</strong>}>
                            <strong>{invoice_date}</strong>
                        </Descriptions.Item> */}
                            {invoice
                                ? <Descriptions.Item span={3} label={`Periods`}>
                                    {invoice.invoice_period}
                                </Descriptions.Item>
                                : null
                            }
                        </Descriptions>
                    </Col>
                </Row>
                <Row style={{ marginTop: 20 }} gutter={20}>
                    <Col span={12} style={{ padding: 25, paddingTop: 0, paddingBottom: 0 }}>
                        <strong>PAKALPOJUMA SNIEDZĒJS:</strong><br />
                        {invoice ? invoice.instructor : ""}<br />
                        <div style={{ whiteSpace: "pre-wrap" }}>
                            {invoice ? invoice.instructor_requisites : ""}
                        </div>
                    </Col>
                    <Col span={12} style={{ padding: 25, paddingTop: 0, paddingBottom: 0 }}>
                        <strong>PAKALPOJUMA SAŅĒMĒJS:</strong><br />
                        {
                            invoice
                                ? Object.keys(invoice?.student_requisites).map((key: any) => {
                                    if (invoice?.student_requisites[key]) {
                                        return invoice?.student_requisites[key]
                                    } else {
                                        return undefined
                                    }
                                }).filter((req: any) => {
                                    return req !== undefined;
                                }).length > 0
                                    ? Object.keys(invoice?.student_requisites).map((key: any) => {
                                        if (invoice?.student_requisites[key]) {
                                            return invoice?.student_requisites[key]
                                        } else {
                                            return undefined
                                        }
                                    }).filter((req: any) => {
                                        return req !== undefined;
                                    })[0]
                                    : invoice ? invoice.student : ""
                                : <></>
                        }
                        {/* SIA "AUTOSKOLA EINŠTEINS"<br />
                        Reģ. nr.: 40103961707<br />
                        Banka: AS Citadele banka<br />
                        Kods: PARXLV22<br />
                        Konta Nr. LV02PARX0020064720004<br />
                        Juridiskā adrese: Raunas iela 6-11, Cēsis,<br />
                        Cēsu nov., LV-4101 */}
                    </Col>
                </Row>
                <Row style={{ marginTop: 20 }} gutter={20}>
                    <Col span={24}>
                        <Table
                            dataSource={invoice?.positions}
                            pagination={false}
                            columns={[
                                {
                                    title: "Apraksts", render: (record: any, all: any) => {
                                        if (all.hasOwnProperty("vehicle")) {
                                            return <span>{`Braukšanas nodarbība (${record.category} kat.)`}</span>
                                        } else {
                                            return <></>
                                        }
                                    }
                                },
                                { title: "Daudzums", dataIndex: "lesson_counter" },
                                { title: "Cena (EUR)", dataIndex: "rate" },
                                { title: "Summa", dataIndex: "total_rate", render: (amount: number) => amount?.toFixed(2) },
                            ] as ColumnsType<Invoice>}
                        />
                    </Col>
                </Row>
                <Row className="print-stuff" style={{ marginTop: 20 }} gutter={20}>
                    <Col span={24}>
                        <strong style={{ textDecoration: "underline" }}>Maksājuma  pamatojumā lūdzam norādīt rēķina numuru.</strong><br />
                        Rēķins sagatavots elektroniski un derīgs bez paraksta.<br />
                        {/* Sagatavoja: {invoice.instructor.first_name} {invoice.instructor.last_name} */}
                    </Col>
                </Row>
            </Card>
        </div>
    </>
}