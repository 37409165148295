import { Button, Card, Col, Row } from "antd";
import React, { useEffect } from "react";
import { InfoSection } from "./InfoSection";
import { RatingSection } from "./RatingSection";

export const StudentCard = (props: any) => {
    const { isMobile, studentRating, student, maxPoints, gradeLVL, category } = props;

    return <Card style={{ margin: isMobile ? 10 : 0, marginBottom: 24 }}>
        <Row gutter={24} style={{
            justifyContent: isMobile ? "center" : "space-between"
        }}>
            <Col span={24} lg={16}>
                {
                    Object.keys(student).length > 0
                        ? <InfoSection
                            isMobile={isMobile}
                            student={student}
                            category={category}
                        />
                        : <Card loading />
                }

            </Col>
            {
                !!student.full_eval
                && <Col span={24} lg={8}>
                    <RatingSection
                        isMobile={isMobile}
                        studentRating={studentRating}
                        maxPoints={maxPoints}
                        gradeLVL={gradeLVL}
                    />
                </Col>
            }
        </Row>
    </Card>
}