import { Collapse, Col, Radio, Button, Row } from "antd"
import React from "react"

export const EvalFormQuestion = (props: any) => {
    const { category, answers, setAnswers, isMobile, subjects, subjectHistory } = props;

    const historyDot = {
        width: 5,
        height: 5,
        position: "absolute",
        borderRadius: "50%",
        backgroundColor: "#525C66",
        top: 4,
        right: 4
    } as any;

    return <>
        {
            subjects.map((subject: any, index: any) => {
                let lastHistoricGrade = "none"
                if (subjectHistory[subject.id]) {
                    if (subjectHistory[subject.id].grades) {
                        lastHistoricGrade = JSON.parse(JSON.stringify(subjectHistory[subject.id].grades)).reverse()[0]
                    }
                }
                return <Col
                    key={subject.id}
                    span={24}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "10px 0",
                        borderBottom: "1px solid #CED4D9"
                    }}
                >
                    <Row style={{ width: "100%" }}>
                        <Col flex={1} style={{ width: isMobile ? "100%" : "auto", flexWrap: "wrap" }}>
                            <p style={{ marginBottom: 12, width: "100%", position: "relative" }}>
                                {
                                    lastHistoricGrade !== "none"
                                    && <div style={{
                                        borderRadius: "50%",
                                        width: 10,
                                        height: 10,
                                        backgroundColor: lastHistoricGrade === "beginner"
                                            ? "#C02227"
                                            : lastHistoricGrade === "medium"
                                                ? "#E6812E"
                                                : lastHistoricGrade === "exam_worthy"
                                                    ? "#1A804D"
                                                    : "",
                                        position: "absolute",
                                        left: -16,
                                        top: 6
                                    }}
                                    />
                                }
                                <span
                                    style={{
                                        color: lastHistoricGrade === "beginner"
                                            ? "#C02227"
                                            : lastHistoricGrade === "medium"
                                                ? "#E6812E"
                                                : lastHistoricGrade === "exam_worthy"
                                                    ? "#1A804D"
                                                    : "",
                                        fontWeight: lastHistoricGrade === "none"
                                            ? "normal"
                                            : "bold"
                                    }}
                                >
                                    {props.parentInd}.{index + 1}.
                                </span> {subject.content_lv}
                            </p>
                        </Col>
                        <Col flex={1} style={{ width: isMobile ? "100%" : "auto" }}>
                            <Radio.Group
                                className="valuate-students-radio-group"
                                options={
                                    [
                                        { label: lastHistoricGrade === "beginner" && <div style={historyDot} />, value: "beginner", style: { width: isMobile ? "100%" : "80px" } },
                                        { label: lastHistoricGrade === "medium" && <div style={historyDot} />, value: "medium", style: { width: isMobile ? "100%" : "80px" } },
                                        { label: lastHistoricGrade === "exam_worthy" && <div style={historyDot} />, value: "exam_worthy", style: { marginRight: 0, width: isMobile ? "100%" : "80px" } }
                                    ]
                                }
                                value={answers?.find((el: any) => el?.id === subject.id)?.grade}
                                onChange={(e) => {
                                    setAnswers((prev: any) => {
                                        const copy = JSON.parse(JSON.stringify(prev));
                                        let newData = copy;
                                        if (copy.some((el: any) => el.id === subject.id)) {
                                            newData = copy.map((answer: any) => {
                                                if (answer.id === subject.id) {
                                                    return {
                                                        ...answer,
                                                        grade: e.target.value
                                                    }
                                                } return answer;
                                            })
                                        } else {
                                            newData.push({
                                                id: subject.id,
                                                grade: e.target.value
                                            })
                                        }
                                        return newData;
                                    })
                                }}
                                optionType="default"
                                buttonStyle="solid"
                            />
                            {
                                answers?.some((el: any) => el?.id === subject.id)
                                    ? <Button
                                        style={{ marginTop: 5 }}
                                        onClick={() => {
                                            setAnswers((prev: any) => {
                                                const copy = JSON.parse(JSON.stringify(prev));
                                                const newData = copy.filter((answer: any) => {
                                                    return answer.id !== subject.id
                                                })
                                                return newData;
                                            })
                                        }}
                                    >
                                        Atcelt izveli
                                    </Button>
                                    : <></>
                            }
                        </Col>
                    </Row>


                </Col>
            })
        }
    </>

}