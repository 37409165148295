import { Button, Checkbox, Col, Input, Modal, Row, Spin, Tag, message } from "antd";
import icStart from "../../../img/ic-ieradies.svg";
import icNotStart from "../../../img/ic-nav-ieradies.svg";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { http } from "../../../helpers/http";
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from "moment";
import { AxiosResponse } from "axios";

export const StartEvaluationModal = (props: any) => {
    const history = useHistory();
    const {
        isVisible,
        setIsVisible,
        slot,
        updateTimeSlots,
        exam,
        started
    } = props;
    const [isMobile, setIsMobile] = useState(false);
    const [routeTime, setRouteTime] = useState(["", ""]);
    const [slotTime, setSlotTime] = useState(["", ""]);
    const [feedback, setFeedback] = useState(null as any | string)
    const [loading, setLoading] = useState(false);
    const [studentInfo, setStudentInfo] = useState(null as any);
    const [sendToCsdd, setSendToCsdd] = useState(false);
    const [failedExams, setFailedExams] = useState(0);
    const [endLearning, setEndLearning] = useState(false);
    const [learningEnded, setLearningEnded] = useState(undefined as any);

    const closeModal = () => {
        setIsVisible(false);
    };

    const studentDidntCome = () => {
        Modal.confirm({
            onOk: () => {
                setLoading(true);
                http.post(`lessons/submit_feedback/${slot.slot_id ?? slot.timeslot_id}`, {
                    status: "student_didnt_come"
                }).then((response: any) => {
                    setLoading(false);
                    console.log(response);
                    updateTimeSlots();
                    closeModal();
                }).catch(() => {
                    message.error("Kļūda!");
                    setLoading(false);
                });
            },
            onCancel: () => closeModal(),
            content: "Apstiprinot, nodarbība tiks atzīmēta kā neapmeklēta."
        })
    }

    const confirmLesson = () => {
        setLoading(true);
        const lesson = slot.student_lesson_id
        if (sendToCsdd) {
            http.post(`student-progress/create-exam/${slot.student_id}`, { category: studentInfo.category_id })
                .then((res: AxiosResponse) => {
                    http.post(`/student-progress/send-to-csdd-exam/${slot.student_id}`, { category: studentInfo.category_id })
                })
        }

        if (endLearning) {
            http.get(`/student-ended-learning/${slot.student_id}/${studentInfo.category_id}?change=1&value=1`).then((response: any) => {
                console.log(response)
            })
        }

        http.post(`lessons/submit_feedback/${slot.slot_id ?? slot.timeslot_id}`, { feedback, status: "done" }).then((response: any) => {
            console.log(response)
            setLoading(false);
            updateTimeSlots();
            closeModal();
        }).catch(() => {
            message.error("Kļūda!");
            setLoading(false);
        })
    }

    const startEvaluation = () => {
        setIsVisible(false);
        const lesson = slot.student_lesson_id
        history.push(`/valuate-student/${slot.student_id}/${lesson}${exam ? '/exam' : ''}`, { startTime: slotTime[0] !== routeTime[0] ? routeTime[0] : slotTime[0] })
    }

    const getStudentInfo = () => {
        http.get(`get-driving-lesson-csdd-allowance/${slot.student_lesson_id}`).then((res: any) => {
            const student = res.data.data.length > 0 ? res.data.data[0] : null;
            setStudentInfo(student)
            if (student) {
                getStudentFailedExams(student?.category_id);
                http.get(`/student-ended-learning/${slot.student_id}/${student?.category_id}`).then((res: any) => {
                    setLearningEnded(!!res.data)
                })
            }
        })
    }

    const getStudentFailedExams = (category: number) => {
        http.get(`/failed-driving-exams/${slot.student_id}/${category}`).then((res: AxiosResponse) => {
            setFailedExams(res.data);
        })
    }

    useEffect(() => {
        setIsMobile(window.matchMedia("(max-width: 767px)").matches)
        getStudentInfo();
        console.log(slot, exam)
    }, []);

    useEffect(() => {
        if (Object.keys(slot).length > 0) {
            let individualTime;
            if (slot.hasOwnProperty("timer")) {
                individualTime = slot?.timer.split(" - ")
            } else {
                individualTime = [
                    slot?.time_from,
                    slot?.time_to
                ]
            }
            setSlotTime(individualTime);
            setRouteTime(individualTime);
        }
    }, [slot]);

    useEffect(() => {
        console.log(sendToCsdd)
    }, [sendToCsdd])

    return <Modal
        visible={isVisible}
        footer={false}
        bodyStyle={{ padding: "40px 40px 20px" }}
        width={580}
        onCancel={closeModal}
    >
        <h1 style={{ textAlign: "center" }}>
            {
                slot?.full_eval
                    ? <>Vai vēlaties sākt vērtēšanu studentam <b>{slot.student_name}</b>?</>
                    : <>Nodarbības vērtējums studentam <b>{slot.student_name}</b></>
            }

        </h1>
        {
            slot?.full_eval
                ? <Row gutter={20}>
                    <Col span={24} sm={12} style={{ marginBottom: 30, order: 0 }}>
                        <Button
                            style={{
                                height: "unset",
                                width: "100%",
                                padding: "40px 20px"
                            }}
                            onClick={startEvaluation}
                        >
                            <div style={{ marginBottom: 20 }}>
                                <img src={icStart} alt="start" />
                            </div>
                            <p><b>Jā, sākt vērtēšanu</b></p>
                        </Button>
                    </Col>
                    <Col span={24} sm={12} style={{ marginBottom: 30, order: isMobile ? 2 : 1 }}>
                        <Button
                            style={{
                                height: "unset",
                                width: "100%",
                                padding: "40px 20px"
                            }}
                            onClick={studentDidntCome}
                        >
                            <div style={{ marginBottom: 20 }}>
                                <img src={icNotStart} alt="start" />
                            </div>
                            <p><b>Nē, students nav ieradies</b></p>
                        </Button>
                    </Col>
                    {
                        (
                            moment(slot?.date !== undefined ? slot?.date : slot?.lesson_date, "YYYY-MM-DD").isSame(moment(), "day")
                            && moment().isBetween(moment(slotTime[0], "HH:mm:ss").subtract(15, 'minutes'), moment(slotTime[1], "HH:mm:ss"), "minutes")
                            && !started
                        ) && <Col span={24} style={{ marginBottom: 20, order: isMobile ? 1 : 2 }}>
                            <Checkbox
                                style={{ marginRight: 8 }}
                                onChange={(e: CheckboxChangeEvent) => {
                                    if (e.target.checked) {
                                        setRouteTime([
                                            moment().format("HH:mm:ss"),
                                            slotTime[1]
                                        ])
                                    } else {
                                        setRouteTime(slotTime)
                                    }
                                }}
                            />
                            <b>Sākt maršruta ierakstīšanu no šī brīža {slotTime[0] !== routeTime[0] ? routeTime[0] : <></>}</b><br />
                            <small>Atzīmējot šo opciju, maršruta ierakstīšana sāksies brīdī, kad tiks sākta vērtēšana, un beigsies apstiprinot vērtēšanu, bet ne vēlāk par ieplānoto nodarbības laiku!</small>
                        </Col>
                    }
                    <Col span={24} style={{ order: 3 }}>
                        <Row>
                            <Button
                                style={{ width: "100%" }}
                                onClick={closeModal}
                            >
                                Atcelt darbību
                            </Button>
                        </Row>
                    </Col>
                </Row>
                : <div>
                    <Row gutter={20}>
                        {
                            studentInfo
                                ? (!!studentInfo?.allow_send_to_csdd_without_school_exam
                                    && Number(studentInfo?.driven_lessons) >= studentInfo?.min_driving_lessons
                                    && !!!studentInfo?.sent_to_csdd_driving
                                    && failedExams >= 2)
                                    ? <Col span={24} style={{ paddingBottom: 20 }}>
                                        <Checkbox
                                            style={{ marginRight: 10 }}
                                            onClick={(e: any) => setSendToCsdd(e.target.checked)}
                                        />
                                        <b>Novirzīt uz CSDD braukšanas eksāmenu</b>
                                    </Col>
                                    : learningEnded === undefined
                                        ? <></>
                                        : Number(studentInfo?.driven_lessons) >= studentInfo?.min_driving_lessons && studentInfo?.category_id !== 1 && !learningEnded
                                            ? <Col span={24} style={{ paddingBottom: 20 }}>
                                                <Checkbox
                                                    style={{ marginRight: 10 }}
                                                    onClick={(e: any) => setEndLearning(e.target.checked)}
                                                />
                                                <b>Noslēgt apmācību</b>
                                            </Col>
                                            : learningEnded
                                                ? <Tag color="green">Apmācība noslēgta</Tag>
                                                : <></>
                                : <Spin />
                        }
                        {
                            !!studentInfo?.sent_to_csdd_driving
                            && <Tag style={{ marginBottom: 20 }} color="green">Students novirzīts CSDD eksāmenam</Tag>
                        }
                        <Col span={24} style={{ paddingBottom: 20 }}>
                            <p><b>Komentārs par nodarbību:</b></p>
                            <Input.TextArea
                                autoSize={{ maxRows: 4, minRows: 4 }}
                                onChange={e => setFeedback(e.target.value)}
                            />
                        </Col>
                        <Col span={24} sm={12} style={{ marginBottom: 30, order: 0 }}>
                            <Button
                                style={{
                                    height: "unset",
                                    width: "100%",
                                    padding: "40px 20px"
                                }}
                                onClick={confirmLesson}
                            >
                                <div style={{ marginBottom: 20 }}>
                                    <img src={icStart} alt="start" />
                                </div>
                                <p><b>Pabeigt nodarbību</b></p>
                            </Button>
                        </Col>
                        <Col span={24} sm={12} style={{ marginBottom: 30, order: isMobile ? 2 : 1 }}>
                            <Button
                                style={{
                                    height: "unset",
                                    width: "100%",
                                    padding: "40px 20px"
                                }}
                                onClick={studentDidntCome}
                            >
                                <div style={{ marginBottom: 20 }}>
                                    <img src={icNotStart} alt="start" />
                                </div>
                                <p><b>Nē, students nav ieradies</b></p>
                            </Button>
                        </Col>
                    </Row>
                </div>
        }
    </Modal>
}