import { Card, Layout } from "antd";
import React, { useEffect, useState } from "react";
import { Header } from "../../../components/Header";
import { StudentCard } from "./StudentCard/StudentCard";
import { TopicsCard } from "./TopicsSection/TopicsCard";
import { StudentLessonsCard } from "./StudentLessons/StudentLessonsCard";
import { LessonHistoryCard } from "./LessonsHistory/LessonsHistoryCard";
import { http } from "../../../helpers/http";
import { Route, Switch, useParams } from "react-router-dom";
import { DrivingStats } from "./TopicsSection/DrivingStats";
import { ActiveExams } from "./ActiveExams";
import { ExamsHistoryCard } from "./LessonsHistory/ExamsHistoryCard";

export const StudentStatsView = (props: any) => {
    const { category } = props;
    const { id } = useParams() as any;
    const [isMobile, setIsMobile] = useState(false);
    const [subjects, setSubjects] = useState([] as any);
    const [studentRating, setStudentRating] = useState(0);
    const [studentLvl, setStudentLvl] = useState<string>("");
    const [student, setStudent] = useState({} as any);
    const [subjectHistory, setSubjectHistory] = useState({} as any);
    const [studentHistory, setStudentHistory] = useState([] as any);
    const [examSubjectHistory, setExamSubjectHistory] = useState({} as any);
    const [studentExamHistory, setStudentExamHistory] = useState([] as any);
    const [examSubjects, setExamSubjects] = useState([] as any);

    const getStudentEvalHistory = () => {
        http.get(`get-student-driving-evaluation-history/${id}`, { params: { category: category.id } }).then((res: any) => {
            console.log(res)
            setStudentHistory(res.data.data);
            setSubjectHistory(res.data.theme_subject_history);
            const copyData = JSON.parse(JSON.stringify(res.data.data));
            const lastEvaluatedLesson = copyData.reverse().find((el: any) => {
                return el?.status.includes("done") && el?.grade;
            });
            setStudentLvl(
                lastEvaluatedLesson
                    ? lastEvaluatedLesson.grade
                    : "beginner"
            )
        })
    };

    const getExamEvalHistory = () => {
        http.get(`get-student-driving-exam-evaluation-history/${id}`, { params: { category: category.id } }).then((res: any) => {
            console.log(res)
            setStudentExamHistory(res.data.data);
            setExamSubjectHistory(res.data.theme_subject_history);
        })
    };

    const getStudentEvalRating = () => {
        http.get(`get-student-driving-evaluation-rating/${id}`, { params: { category: category.id } }).then((res: any) => {
            console.log(res)
            setStudentRating(res.data)
        })
    };

    const getStudent = () => {
        http.get(`get-student-info/${id}`, { params: { category: category.id } }).then((res: any) => {
            console.log(res)
            setStudent(res.data.data[0])
        })
    };

    const fetchSubjects = () => {
        http.get(`driving-theme-subjects`, { params: { category: category.id } }).then((res: any) => {
            console.log(res)
            setSubjects(res.data.data);
        })
    };

    const fetchExamSubjects = () => {
        http.get(`driving-exam-theme-subjects`, { params: { category: category.id } }).then((res: any) => {
            console.log(res)
            setExamSubjects(res.data.data);
        })
    };

    useEffect(() => {
        getStudentEvalHistory();
        getExamEvalHistory();
        getStudentEvalRating();
        fetchSubjects();
        fetchExamSubjects();
        getStudent();
        setIsMobile(window.matchMedia("(max-width: 767px)").matches)

        window.addEventListener("resize", () => {
            setIsMobile(window.matchMedia("(max-width: 767px)").matches)
        })
    }, []);

    useEffect(() => {
        if (studentExamHistory.length > 0) {
            setStudentLvl("exam_worthy")
        }
    }, [studentExamHistory]);

    return <div>
        <Switch>
            <Route exact path="/student-stats/:id/lesson-overview/:lessonID">
                <DrivingStats
                    subjects={subjects}
                    isMobile={isMobile}
                    studentHistory={studentHistory}
                />
            </Route>
            <Route exact path="/student-stats/:id">
                <StudentCard
                    isMobile={isMobile}
                    studentRating={studentRating}
                    student={student}
                    maxPoints={subjects.length}
                    gradeLVL={studentLvl}
                    category={category}
                />
                <ActiveExams
                    isMobile={isMobile}
                    category={category}
                />
                <StudentLessonsCard
                    isMobile={isMobile}
                    category={category}
                />
                {
                    !!student.full_eval
                    && <TopicsCard
                        isMobile={isMobile}
                        subjectHistory={subjectHistory}
                        studentHistory={studentHistory}
                        category={category}
                    />
                }
                {
                    studentExamHistory.length > 0
                    && <ExamsHistoryCard
                        isMobile={isMobile}
                        studentHistory={studentExamHistory}
                        subjects={examSubjects}
                    />
                }
                {
                    studentHistory.length > 0
                    && <LessonHistoryCard
                        isMobile={isMobile}
                        studentHistory={studentHistory}
                        subjects={subjects}
                    />
                }
            </Route>
        </Switch>
    </div>
}