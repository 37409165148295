import { Row, Col, Progress } from "antd"
import React from "react"

export const RatingSection = (props: any) => {
    const { isMobile, studentRating, maxPoints, gradeLVL } = props;
    const translation = {
        beginner: "Iesācējs",
        medium: "Vidējs",
        exam_worthy: "Tuvojas eks."
    } as any;

    return <Row style={{
        flexWrap: "nowrap",
        flexDirection: isMobile ? "column" : "row",
        alignItems: "center"
    }}>
        <div style={{
            paddingRight: isMobile ? 0 : 14,
            order: isMobile ? 2 : 1
        }}>
            <h2 style={{
                marginBottom: 4,
                textAlign: isMobile ? "center" : "left"
            }}>
                Studenta reitings
            </h2>
            <p style={{
                marginBottom: 4,
                lineHeight: "16px",
                textAlign: isMobile ? "center" : "left"
            }}>
                <small>Studenta reitings attiecināms uz nodarbību rezultātu.</small>
            </p>
            <p style={{
                margin: 0,
                lineHeight: "16px",
                textAlign: isMobile ? "center" : "left"
            }}>
                <small>Apmācības līmenis: {translation[gradeLVL]}</small>
            </p>
        </div>
        <div style={{
            display: "flex",
            alignItems: "center",
            order: isMobile ? 1 : 2
        }}>
            <Progress
                percent={Number((studentRating / (maxPoints * 0.01)).toFixed(0))}
                format={(steps: any) => `${studentRating}`}
                type="circle"
                width={80}
                style={{ marginBottom: isMobile ? 20 : 0 }}
            />
        </div>
    </Row>
}