import { Button, Card, Col, Row } from "antd";
import React from "react";
import { InfoSection } from "./InfoSection";
import { RatingSection } from "./RatingSection";

export const StudentCard = (props: any) => {
    const { isMobile, student, rating, maxPoints, gradeLVL } = props;
    return <Card style={{ marginBottom: 24, marginRight: 20 }}>
        <Row gutter={24} style={{
            justifyContent: "center"
        }}>
            <Col span={24}>
                <InfoSection
                    isMobile={isMobile}
                    student={student}
                    counters={props.counters}
                />
            </Col>
            <Col span={24}>
                <RatingSection
                    isMobile={isMobile}
                    rating={rating}
                    maxPoints={maxPoints}
                    gradeLVL={gradeLVL}
                />
            </Col>
        </Row>
    </Card>
}