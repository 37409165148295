import { Row, Col } from "antd";
import React from "react";

export const EvalAnswerHelper = (props: any) => {
    const {
        isMobile
    } = props;

    return <Row
        align="middle"
        style={
            {
                backgroundColor: isMobile ? "#6C7680" : "unset",
                width: isMobile ? "100%" : 252,
                marginRight: !isMobile ? -28 : 0,
                position: isMobile ? "fixed" : "static",
                top: 42,
                zIndex: 2
            }
        }
    >
        <Col span={8} style={{ borderLeft: isMobile ? "1px solid #FFFFFF" : "unset" }}>
            <p style={{ textAlign: "center", margin: 0, color: "#FFFFFF" }}>Nav apgūts</p>
        </Col>
        <Col span={8} style={{ borderLeft: isMobile ? "1px solid #FFFFFF" : "unset" }}>
            <p style={{ textAlign: "center", margin: 0, color: "#FFFFFF" }}>Daļēji apgūts</p>
        </Col>
        <Col span={8} style={{ borderLeft: isMobile ? "1px solid #FFFFFF" : "unset", borderRight: isMobile ? "1px solid #FFFFFF" : "unset" }}>
            <p style={{ textAlign: "center", margin: 0, color: "#FFFFFF" }}>Apgūts</p>
        </Col>
    </Row>
}