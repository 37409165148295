import { Row, Col, Button } from "antd"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { http } from "../../../../helpers/http";

export const InfoSection = (props: any) => {
    const { isMobile, student, category } = props;
    const { id } = useParams() as any;
    const [counters, setCounters] = useState({} as any);

    const getStudentCounters = () => {
        http.get(`get-student-driving-info/${id}/${category.id}`).then((res: any) => {
            console.log(res)
            setCounters(res.data.data[0])
        })
    };

    useEffect(() => {
        getStudentCounters();
    }, []);

    return <Row gutter={24} justify="space-between">
        <Col style={{ width: isMobile ? "100%" : "auto" }}>
            <Row align="middle" style={{
                flexDirection: isMobile ? "column" : "row"
            }}>
                <div
                    style={{
                        width: 60,
                        height: 60,
                        borderRadius: "50%",
                        backgroundColor: "#E6F5F7",
                        color: "#0889A8",
                        fontSize: 21,
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: isMobile ? 0 : 24,
                        marginBottom: isMobile ? 20 : 0
                    }}
                >
                    {student.initials}
                </div>
                <div style={{
                    width: isMobile ? "100%" : "auto",
                    marginBottom: isMobile ? 40 : 0
                }}>
                    <h2 style={{
                        textAlign: isMobile ? "center" : "left",
                        marginBottom: isMobile ? 20 : 0
                    }}>
                        {student.first_name} {student.last_name} {isMobile ? <br /> : <></>}(Apgūst {category.value} kategoriju)
                    </h2>
                    <Row
                        gutter={24}
                        justify={isMobile ? "center" : "start"}
                        style={isMobile && {
                            borderTop: "1px solid #CED4D8",
                            borderBottom: "1px solid #CED4D8",
                            padding: "10px 0"
                        }}
                    >
                        <Col style={{ display: "flex", flexDirection: "column" }}>
                            <small style={{ order: isMobile ? 2 : 1 }}>Apmeklētas</small>
                            <div style={{ order: isMobile ? 1 : 2 }}>
                                <b style={{ fontSize: 17, display: "block", textAlign: "center" }}>
                                    {counters?.done}
                                </b>
                            </div>
                        </Col>
                        <Col style={{ display: "flex", flexDirection: "column" }}>
                            <small style={{ order: isMobile ? 2 : 1 }}>Ieplānotas</small>
                            <div style={{ order: isMobile ? 1 : 2 }}>
                                <b style={{ fontSize: 17, display: "block", textAlign: "center" }}>
                                    {counters?.new}
                                </b>
                            </div>
                        </Col>
                        <Col style={{ display: "flex", flexDirection: "column" }}>
                            <small style={{ order: isMobile ? 2 : 1 }}>Neapmeklētas</small>
                            <div style={{ order: isMobile ? 1 : 2 }}>
                                <b style={{ fontSize: 17, display: "block", textAlign: "center" }}>
                                    {counters?.student_didnt_come}
                                </b>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Row>
        </Col >
        <Col style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "center",
            margin: isMobile ? "-20px auto 20px" : "0"
        }}>
            <Button
                style={{ marginRight: 8 }}
            >
                <a href={`mailto:${student.email}`}>
                    Rakstīt epastu
                </a>
            </Button>
            <Button>
                <a href={`tel:${student.phone_number}`}>
                    Zvanīt
                </a>
            </Button>
        </Col>
    </Row >
}