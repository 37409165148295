import { List, Button, Row, Col, DatePicker, Card, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

export const LessonsList = (props: any) => {
    const [freeLessons, showFree] = useState(false);
    const [selectedDate, setDate] = useState("");
    const [period, setPeriod] = useState(null as any);

    function groupBy(arr: any, property: any) {
        return arr.reduce(function (memo: any, x: any) {
            if (!memo[x[property]]) {
                memo[x[property]] = [];
            }
            memo[x[property]].push(x);
            return memo;
        }, {});
    }

    // useEffect(() => {
    //     console.log(period)
    // }, [period])

    const sortedDays = groupBy(props.slots, "date");

    const onDatePick = (date: any, dateString: string) => {
        setDate(dateString);
    };

    const resetDatePick = () => {
        setDate("");
    }

    const showFreeHandler = () => {
        freeLessons ? showFree(false) : showFree(true);
    };

    const fetchByPeriod = () => {
        if (period === null) return;
        props.fetchByPeriod(true, period);
    }

    return (
        <div>
            <DatePicker
                style={{ marginBottom: "20px" }}
                defaultValue={moment()}
                format={props.dateFormat}
                onChange={onDatePick}
            />
            <Button onClick={showFreeHandler} style={{ marginBottom: "20px", display: "block" }}>
                {freeLessons
                    ? "Parādīt dienu sarakstu"
                    : "Parādīt visas brīvās nodarbības"}
            </Button>
            <div style={{ border: "1px solid rgb(203 203 203)", padding: 10, marginBottom: 20 }}>
                <DatePicker.RangePicker
                    className="mobile-range-picker"
                    format={props.dateFormat}
                    onChange={setPeriod}
                    value={period}
                />
                <Button onClick={fetchByPeriod} className="mobile-range-picker-btn">Parādīt</Button>
            </div>
            {freeLessons ? props.dateCell(moment(), true) : <></>}
            {
                props.loading
                    ? <Row justify="center"><Spin size="large" /></Row>
                    : selectedDate.length < 1 ? Object.keys(sortedDays)
                        .sort()
                        .map((day: any) => {
                            return (
                                <Day
                                    key={day}
                                    date={day}
                                    list={sortedDays[day]}
                                    addSlot={props.addSlot}
                                    dateCell={props.dateCell}
                                    freeLessons={freeLessons}
                                />
                            );
                        }) : <Day
                        date={selectedDate}
                        list={sortedDays[selectedDate]}
                        dateCell={props.dateCell}
                        expanded={true}
                        openDate={true}
                        resetDate={resetDatePick}
                    />
            }
        </div>
    );
};

const Day = (props: any) => {
    const [expanded, setExpanded] = useState(props.expanded);
    const [isSingleDate, toggleDate] = useState(props.openDate);

    const expandHandler = () => {
        expanded ? setExpanded(false) : setExpanded(true);
    };

    const addSlotModal = () => {
        props.addSlot(props.date);
    };

    useEffect(() => {
        setExpanded(props.expanded);
    }, [props.expanded]);

    React.useEffect(() => {
        toggleDate(props.openDate);
    }, [props.openDate])

    const backToList = () => {
        toggleDate(false);
        setExpanded(false);
        props.resetDate();
    }

    return (
        <div>
            {props.freeLessons ? (
                <div></div>
            ) : (
                <List
                    className="mobile-calendar-list"
                    style={
                        expanded
                            ? { backgroundColor: "#F0F0F0" }
                            : { backgroundColor: "#FFF" }
                    }
                    header={
                        <Row justify={"space-between"}>
                            <Col span={12}>
                                <button
                                    style={{
                                        border: 0,
                                        backgroundColor: "transparent",
                                        width: "100%",
                                        textAlign: "left",
                                    }}
                                    onClick={expandHandler}
                                >
                                    {props.date}
                                </button>
                            </Col>
                            <Col span={12}>
                                {expanded ? (
                                    <Button onClick={addSlotModal}>
                                        Pievienot nodarbību
                                    </Button>
                                ) : (
                                    <></>
                                )}
                            </Col>
                        </Row>
                    }
                    bordered
                >
                    {expanded ? (
                        <div
                            style={{
                                padding: "5px",
                                borderTop: "1px solid #c9c9c9",
                            }}
                        >
                            {props.dateCell(moment(props.date))}
                            {isSingleDate ? <Button onClick={backToList} style={{ margin: 5 }}>
                                Atpakaļ uz visu sarakstu
                            </Button> : <></>}
                        </div>
                    ) : (
                        <></>
                    )}
                </List>
            )}
        </div>
    );
};
