import { Button, Col, Row, Tag, Tooltip } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { ColoredBadge } from "../../components/ColoredBadge";
import { ReactComponent as IcError } from "../../../../img/ic-error.svg";
import { ReactComponent as IcWarning } from "../../../../img/ic-warning.svg";

const Status = (props: any) => {
    const { status, lesson } = props;


    const warningStyle = {
        display: "flex",
        padding: "6px 16px 6px 6px",
        justifyContent: "end",
        alignItems: "center",
        width: "100%",
        fontSize: 12,
    }

    if (status.includes("student_cancelled")) {
        return <div style={{ ...warningStyle, color: "#C02227" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <IcError />
                <b style={{ marginLeft: 6 }}>Students atcēla nodarbību</b> {/*Tulkojums*/}
            </div>
        </div>
    } else if (status.includes("student_didnt_come")) {
        return <div style={{ ...warningStyle, color: "#C02227" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <IcError />
                <b style={{ marginLeft: 6 }}>Students neieradās</b> {/*Tulkojums*/}
            </div>
        </div>
    } else if (status.includes("instructor_cancelled")) {
        return <div style={{ ...warningStyle, color: "#E6812E" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <IcWarning />
                <b style={{ marginLeft: 6 }}>Instruktors atcēla nodarbību</b> {/*Tulkojums*/}
            </div>
        </div>
    } else if (status.includes("exam")) {
        return <div style={{ ...warningStyle, color: "#E6812E" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                {
                    status.includes("passed")
                        ? <Tag color="#9ACD32">Nokārtots</Tag>
                        : <Tag color="#DC143C">Nav nokārtots</Tag>
                }
            </div>
        </div>
    }

    return !!lesson.full_eval
        ? <>
            <ColoredBadge
                type="exam_worthy"
                number={lesson.grade_gradation.exam_worthy}
            />
            <ColoredBadge
                type="medium"
                number={lesson.grade_gradation.medium}
            />
            <ColoredBadge
                type="beginner"
                number={lesson.grade_gradation.beginner}
            />
        </>
        : <></>
}

export const LessonHeader = (props: any) => {
    const {
        isMobile,
        lesson
    } = props;

    return <Row justify="space-between">
        <Col>
            <Row gutter={16}>
                <Col>
                    {lesson.lesson_date} / {lesson.time_from} - {lesson.time_to}
                </Col>
                <Col>
                    {lesson.instructor_name}
                </Col>
            </Row>
        </Col> <Col span={24} lg={8}>
            <Row style={{ flexWrap: "nowrap" }} align="middle" justify="end">
                <Status status={lesson.status} lesson={lesson} />
                {/* <Button className="btn-black" style={{ marginLeft: 20 }}>
                    Atvērt
                </Button> */}
            </Row>
        </Col>
    </Row>
}

