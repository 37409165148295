import React from "react";
import "../../../../../css/StudentEvaluation.scss";

export const EvaluationList = (props: any) => {
    const { grades, subjects } = props;

    const translation = {
        beginner: "Nav pietiekami",
        medium: "Pietiekami",
        exam_worthy: "Labi",
    } as any;

    return <div>
        {
            Object.keys(translation)?.map((key: any, index: any) => {
                return <ul className="student-eval-list" key={index}>
                    <li className={key}>
                        {translation[key]}
                        <div className={`questions-counter ${key}`}>
                            {grades[key]?.total ?? 0}
                        </div>
                    </li>
                    {
                        grades[key]?.subject_ids?.sort((a: any, b: any) => Number(a) > Number(b) ? 1 : -1).map((question: any, index: any) => {
                            const text = subjects.find((el: any) => el.id === question);
                            const ind = subjects.indexOf(subjects.find((el: any) => el.id === question));
                            return <li key={index}>
                                <p><span style={{ color: "#828E99" }}></span> {text?.content_lv}</p>
                            </li>
                        })
                    }
                </ul>
            })
        }
    </div>
}