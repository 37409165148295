import { Card, Layout } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Header } from "../../../../components/Header";
import { LessonInfoCard } from "../LessonsHistory/LessonInfo/LessonInfoCard";

export const DrivingStats = (props: any) => {
    const { lessonID } = useParams() as any;
    const { isMobile, subjects, studentHistory } = props;
    const [lesson, setLesson] = useState({} as any);

    useEffect(() => {
        if (studentHistory.length > 0) {
            console.log(studentHistory, lessonID)
            setLesson(studentHistory.find((el: any) => el.student_lesson_id === Number(lessonID)))
        }
    }, [studentHistory]);

    return <Card>
        {
            lesson || lesson !== undefined
                ? Object.keys(lesson)?.length > 0
                    ? <LessonInfoCard isMobile={isMobile} lesson={lesson} subjects={subjects} />
                    : <></>
                : <></>
        }
    </Card>
}