import moment from "moment";
import React, { useEffect, useState } from "react";
import { LessonSlot } from "./LessonSlot";

export const LessonsList = (props: any) => {
    const { slots, meetingPlace, updateTimeSlots } = props;
    const [todaySlots, setTodaySlots] = useState([] as any);
    const [startedSlots, setStartedSlots] = useState([] as any);

    const checkIfStartedEval = () => {
        const statsStr = localStorage.getItem("stats");
        if (statsStr) {
            const stats = JSON.parse(statsStr);
            setStartedSlots(stats)
        }
    }

    useEffect(() => {
        if (slots.length > 0) {
            setTodaySlots(() => {
                return slots.sort((a: any, b: any) =>
                    moment(a.time_from, "HH:mm:ss").isAfter(moment(b.time_from, "HH:mm:ss"), "hours") ? 1 : -1
                )
            })
        }
        checkIfStartedEval();
    }, [slots]);

    return todaySlots.some((el: any) => !el.slot_status.includes("instructor"))
        ? todaySlots.some((el: any) => !el.slot_status.includes("student"))
            ? <div style={{ marginBottom: 40 }}>
                <h2>{meetingPlace}</h2>
                <ul
                    className="day-slots-list"
                    style={{
                        padding: 0,
                        margin: 0,
                    }}
                >
                    {
                        todaySlots?.map((slot: any, index: any) => {
                            const started = startedSlots.some((item: any) => Number(item.lesson_id) === slot.student_lesson_id)
                            return <LessonSlot started={started} updateTimeSlots={updateTimeSlots} key={index} slot={slot} />
                        })
                    }
                </ul>
            </div>
            : <></>
        : <></>
}