import { Row } from "antd";
import React from "react";
import { DifficultySwitch } from "./DifficultySwitch";

export const EvalCardHeader = (props: any) => {
    const {
        isMobile,
        returnStudentLvl,
        studentLVL,
        exam
    } = props;

    return <Row justify={isMobile ? "center" : "space-between"}>
        {!isMobile && <h3>Audzēkņa {exam && "eksāmena"} vērtēšana</h3>}
        {exam || <DifficultySwitch isMobile={isMobile} returnStudentLvl={returnStudentLvl} studentLVL={studentLVL} exam={exam} />}
    </Row>
}