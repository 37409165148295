import { Modal, Card, Row, Col, Space, Input, Button, Typography } from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { http } from "../../../helpers/http";

const errorStatuses = {
    "other_reserved": "Kāds cits jau ir rezervējis šo laiku",
    "slot_not_active": "Nodarbība nav aktīva",
    "lesson_start_time_offset_fail": "Nodarbības rezevācijas laiks ir pārāk tuvu",
    "student_already_signed_to_datetime": "Šis laiks jau ir rezervēts",
    "someone_already_bought": "Kāds cits jau ir rezervējis šo laiku",
    "student_dont_have_category": "Students nav šīs kategorijas",
    "daily_driving_limit_reached": "Dienas braukšanas limits sasniegts",
    "not_enough_balance": "Nepietiek līdzekļu",
    "student_not_found": "Students netika atrasts",
    "short_phone_nr": "Nepareizs tālruņa numurs",
} as any;

export const AddStudentModal = (props: any) => {
    const {
        slot,
        setModalVisible,
        modalVisible,
        updateTimeSlots
    } = props;
    const [studentNumber, setStudentNumber] = useState("");
    const [studentPhoneError, setStudentPhoneError] = useState(false);
    const [studentBalanceError, setStudentBalanceError] = useState(false);
    const [addingStudent, setAddingStudent] = useState(false);
    const [error, setError] = useState("");

    const addStudent = () => {
        setAddingStudent(true);
        setStudentPhoneError(false);
        setStudentBalanceError(false);
        http.post(`time_slots/${slot.slot_id}/add_student`, { phone_number: studentNumber }).then((response) => {
            // setAddingStudent(false);
            setError("");
            updateTimeSlots(response.data.data);
            resetModal();
        }).catch((e: any) => {
            // console.log("student error", e.response);
            if (e.response && e.response.data.data.reason) {
                setError(e.response.data.data.reason);
            }
            // if (e.response && e.response.data.data === "Student not found.") {
            //     setStudentPhoneError(true);
            // } else if (e.response && e.response.data.message === "Not enough balance.") {
            //     setStudentBalanceError(true);
            // }
            setAddingStudent(false);
        });
    }

    const resetModal = () => {
        setStudentNumber("");
        setModalVisible(false);
    }

    useEffect(() => {
        // console.log(slot)
    }, [modalVisible]);

    return <Modal
        visible={modalVisible}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
        title={<b>{moment(slot.lesson_date).format("DD.MM.YYYY")}&nbsp;&nbsp;&nbsp;
            {moment(slot.time_from, "HH:mm:ss").format("HH:mm")} - {moment(slot.time_to, "HH:mm:ss").format("HH:mm")}</b>}
        footer={() => <></>}
        zIndex={1031}
    >
        <Card
            title="Pievienot studentu"
        >
            <Row>
                <Col>
                    <b>Studenta tālruņa numurs</b><br />
                    <Space>
                        <Input
                            value={studentNumber}
                            onChange={(e: any) => {
                                setStudentNumber(e.target.value)
                                setStudentPhoneError(false);
                            }}
                        />
                        <Button
                            type="primary"
                            disabled={studentNumber.length === 0}
                            onClick={addStudent}
                        >
                            Pievienot
                        </Button>
                    </Space>
                    {
                        error.length > 0
                        && <div style={{ marginTop: 2 }}>
                            <Typography.Text type="danger">{errorStatuses[error] ?? error}</Typography.Text>
                        </div>
                    }
                    {/* {
                        studentPhoneError
                            ? <div><b style={{ color: "red" }}>Students nav atrasts!</b></div>
                            : <></>
                    } */}
                </Col>
            </Row>
        </Card>
    </Modal>
}