import { Col, Row } from "antd";
import moment from "moment";
import React, { useState } from "react";
//@ts-ignore
import Geocode from "react-geocode";

export const Point = (props: any) => {
    const { point, ind } = props;

    return <div style={{ marginBottom: 20 }}>
        <Row align="middle">
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                width: 32,
                height: 32,
                backgroundColor: "#FA3A00",
                color: "#FFFFFF",
                marginRight: 10
            }}>
                {ind}
            </div>
            <Col>
                <p style={{ margin: 0 }}><b>{point?.formated_address ?? ""}</b></p>
                <small>
                    Laiks: {point.action_time}
                    {/* {point.speed ? `Ātrums: ${point.speed}` : ""} */}
                </small><br />
                {/* <small>({point.lat} {point.lng})</small> */}
            </Col>
            {
                point.comment
                && <Col span={24} style={{ paddingTop: 14 }}>
                    <div
                        style={{
                            backgroundColor: "#F0F2F5",
                            padding: "16px 20px"
                        }}
                    >
                        <p style={{ margin: 0, color: "#525C66" }}>{point.comment}</p>
                    </div>
                </Col>
            }
        </Row>
    </div>
}