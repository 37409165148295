import { Card, Layout, PageHeader } from "antd";
import React, { useEffect } from "react";
import { ServiceCard } from "./ServiceCard";

export const ExchangeCarsLayout = (props: any) => {

    return <Layout className="main-content">
        <PageHeader
            title="Maiņas auto"
            style={{ backgroundColor: "#FFFFFF", paddingLeft: 60 }}
        />
        <Card
            title={"Jūsu transportlīdzekļi"}
        >
            {
                props.self?.services?.map((service: any) => {
                    return <ServiceCard
                        service={service}
                        refresh={props.refresh}
                    />
                })
            }
        </Card>
    </Layout>
}