import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { http } from "../../../helpers/http";
import { AxiosResponse } from 'axios';
import { StudentStatsView } from "./StudentStatsView";
import { Layout, Tabs } from 'antd';
import { Header } from "../../../components/Header";

export const StatsSection = () => {
    const { id }: { id: string } = useParams();
    const [categories, setCategories] = useState([] as any);

    const getStudentCategories = () => {
        http.get(`get-student-categories/${id}`).then((res: AxiosResponse) => {
            setCategories(res.data.student_categories)
        })
    }

    useEffect(() => {
        getStudentCategories();
    }, []);

    useEffect(() => {
        console.log(categories)
    }, [categories])

    return <Layout className="main-content" style={{ marginBottom: 67 }}>
        <Header />
        {
            categories.length === 0
                ? <></>
                : categories.length === 1
                    ? <StudentStatsView category={categories[0]} />
                    : <Tabs style={{ backgroundColor: "#FFFFFF", padding: "0 20px" }}>
                        {
                            categories.map((category: any) => {
                                return <Tabs.TabPane tab={category.value} key={category.id}>
                                    <StudentStatsView category={category} />
                                </Tabs.TabPane>
                            })
                        }
                    </Tabs >
        }
    </Layout>
}