import { Col, Radio, Button } from "antd";
import React, { useEffect, useState } from "react";

export const DifficultySwitch = (props: any) => {
    const {
        isMobile,
        returnStudentLvl,
        studentLVL
    } = props;

    useEffect(() => {
        returnStudentLvl(studentLVL)
    }, [studentLVL])

    return <Col style={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: isMobile ? "baseline" : "center" }}>
        <p
            style={{
                margin: 0,
                marginBottom: isMobile ? 10 : 0,
                marginRight: isMobile ? 0 : 10
            }}>
            <b>Nodarbības sarežģītība:</b>
        </p>
        <Radio.Group
            buttonStyle="solid"
            value={studentLVL}
            onChange={(e: any) => {
                returnStudentLvl(e.target.value)
            }}
        >
            <Radio.Button style={{ marginLeft: 4 }} value={"beginner"}>Iesācējs</Radio.Button>
            <Radio.Button style={{ marginLeft: 4 }} value={"medium"}>Vidējs</Radio.Button>
            <Radio.Button style={{ marginLeft: 4 }} value={"exam_worthy"}>Tuvojas eks.</Radio.Button>
        </Radio.Group>
    </Col>
}