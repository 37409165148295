import { Card, Col, message, Row, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { Route, Switch, useParams } from "react-router-dom";
import { http } from "../../helpers/http";
import { AxiosResponse } from 'axios';
import moment from "moment";
import { Invoice } from "./Invoice";
import { useHistory } from 'react-router';

export const StudentInvoicesList = () => {
    const history = useHistory();
    const { id }: { id: string | undefined } = useParams();
    const [detailedInvoice, setDetailedInvoice] = useState({} as any);
    const [loading, setLoading] = useState(false);


    const fetchInvoiceDetails = () => {
        setLoading(true);
        http.get(`invoices/get-invoice-details/${id}`).then((res: AxiosResponse) => {
            console.log(res)
            setDetailedInvoice(res.data.data)
            setLoading(false);
        }).catch(() => {
            message.error("Kļūda!")
            setLoading(false);
        })
    }

    useEffect(() => {
        fetchInvoiceDetails();
    }, []);

    return <Switch>
        <Route exact path={"/invoices/:id"}>
            {
                loading
                    ? <Skeleton />
                    : <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <h1><b>Rēķins:</b> {detailedInvoice?.invoice_number}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Periods:</b> {detailedInvoice?.period}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Izrakstīts:</b> {moment(detailedInvoice?.date, "YYYY-MM-DD").format("DD.MM.YYYY")}</h1>
                        </Col>
                        {
                            detailedInvoice?.students?.map((student: any) => {
                                return <Col span={24} key={student.id}>
                                    <Card hoverable onClick={() => history.push(`/invoices/${id}/${student.id}`)}>
                                        <Row justify="space-between">
                                            <Col span={24} sm={6}>
                                                <p style={{ marginBottom: 4 }}><b>Students:</b></p>
                                                {student?.student}
                                            </Col>
                                            <Col span={24} sm={6}>
                                                <p style={{ marginBottom: 4 }}><b>Rēķins:</b></p>
                                                {student?.invoice_details?.student_invoice_number}
                                            </Col>
                                            <Col span={24} sm={6}>
                                                <p style={{ marginBottom: 4 }}><b>Nodarbības:</b></p>
                                                {student?.invoice_details?.lessons}
                                            </Col>
                                            <Col span={24} sm={6}>
                                                <p style={{ marginBottom: 4 }}><b>Summa:</b></p>
                                                {student?.invoice_details?.sum?.toFixed(2)} &euro;
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            })
                        }
                    </Row>
            }
        </Route>
        <Route path={"/invoices/:id/:studentID"}>
            <Invoice />
        </Route>
    </Switch>
}