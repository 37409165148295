import { Card, Collapse } from "antd";
import React, { useEffect } from "react";
import { LessonHeader } from "./LessonHeader";
import { ExamInfoCard } from "./LessonInfo/ExamInfoCard";
import { LessonInfoCard } from "./LessonInfo/LessonInfoCard";

export const ExamsHistoryCard = (props: any) => {
    const { studentHistory, subjects } = props;
    const {
        isMobile
    } = props;

    useEffect(() => {
        console.log(studentHistory)
    }, [studentHistory])

    return <Card
        title="Braukšanas eksāmenu vēsture"
        bodyStyle={{
            padding: isMobile ? 0 : 24
        }}
        style={{
            margin: isMobile ? "0 0 24px" : 24
        }}
    >
        <Collapse expandIconPosition="right">
            {
                studentHistory?.map((lesson: any, index: any) => {
                    return <Collapse.Panel key={index} header={<LessonHeader lesson={lesson} />}>
                        {
                            lesson.status.includes("cancelled")
                                || lesson.status.includes("didnt_come")
                                ? lesson.comment ? <b>{lesson.comment}</b> : lesson.feedback ? <b>{lesson.feedback}</b> : <b>Nav pievienots komentārs</b>
                                : <ExamInfoCard isMobile={isMobile} lesson={lesson} subjects={subjects} />
                        }
                    </Collapse.Panel>
                })
            }
        </Collapse>
    </Card>
}