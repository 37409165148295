import React, { useEffect, useState } from "react";
import { StartEvaluationModal } from "../../components/StartEvaluationModal";
import { LessonSlot } from "./LessonSlot";

export const LessonsList = (props: any) => {
    const { slots, updateTimeSlots, exam } = props;
    const [visible, setVisible] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState({} as any);
    const [startedSlots, setStartedSlots] = useState([] as any);

    const checkIfStartedEval = () => {
        const statsStr = localStorage.getItem("stats");
        if (statsStr) {
            const stats = JSON.parse(statsStr);
            console.log(stats)
            setStartedSlots(stats)
        }
    }

    useEffect(() => {
        console.log(exam)
    }, [exam])

    useEffect(() => {
        checkIfStartedEval();
    }, [])

    return <div style={{ marginBottom: 40 }}>
        <ul
            className="day-slots-list"
            style={{
                padding: 0,
                margin: 0,
            }}
        >
            {
                slots?.map((slot: any, index: any) => {
                    const started = startedSlots.some((item: any) => Number(item.lesson_id) === slot.student_lesson_id)
                    return <LessonSlot
                        started={started}
                        key={index}
                        slot={slot}
                        index={index}
                        setSlot={setSelectedSlot}
                        setModalVisible={setVisible}
                        updateTimeSlots={updateTimeSlots}
                    />
                })
            }
        </ul>
        {
            visible
            && <StartEvaluationModal
                isVisible={visible}
                setIsVisible={setVisible}
                slot={selectedSlot}
                updateTimeSlots={updateTimeSlots}
                exam={exam}
            />
        }
    </div>
}