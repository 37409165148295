import { Card, Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { http } from "../../../../helpers/http";
import { QuestionHeader } from "./QuestionHeader";
import { QuestionHistory } from "./QuestionHistory";
import { TopicHeader } from "./TopicHeader";

export const TopicsCard = (props: any) => {
    const {
        isMobile,
        subjectHistory,
        studentHistory,
        category
    } = props;

    const [themes, setThemes] = useState([] as any);
    const [data, setData] = useState([] as any);

    const fetchQuestions = () => {
        http.get(`driving-themes`, { params: { category: category.id } }).then((res: any) => {
            setThemes(res.data.data);
        })
    }

    useEffect(() => {
        fetchQuestions();
    }, []);

    useEffect(() => {
        const newSubjects = themes.map((theme: any) => {
            return {
                ...theme,
                subjects: theme.subjects?.map((subject: any) => {
                    return {
                        ...subject,
                        results: subjectHistory[subject.id]
                    }
                })
            }
        })
        setData(newSubjects);
    }, [themes, subjectHistory])

    return <Card
        title="Nodarbību plāns"
        bodyStyle={{
            padding: isMobile ? 0 : 24
        }}
        style={{
            margin: isMobile ? "0 0 24px" : 24
        }}
    >
        <Collapse expandIconPosition="right">
            {
                data.map((theme: any, index: any) => {
                    return <Collapse.Panel
                        key={index}
                        header={<TopicHeader
                            isMobile={isMobile}
                            number={index + 1}
                            active={false}
                            topic={theme}
                            description={theme.tooltip_lv}
                            questions={theme.subjects}
                            subjectHistory={subjectHistory}
                        />}
                    >
                        <Collapse expandIconPosition="right">
                            {
                                theme.subjects.map((question: any, ind: any) => {
                                    const slots = studentHistory?.filter((lesson: any) => {
                                        if (Array.isArray(lesson.grades)) return false;
                                        const beginner = lesson.grades.beginner?.subject_ids?.some((el: any) => el === question.id)
                                        const medium = lesson.grades.medium?.subject_ids?.some((el: any) => el === question.id)
                                        const exam_worthy = lesson.grades.exam_worthy?.subject_ids?.some((el: any) => el === question.id)
                                        return beginner || medium || exam_worthy
                                    })
                                    return <Collapse.Panel
                                        key={ind}
                                        header={<QuestionHeader
                                            isMobile={isMobile}
                                            number={`${index + 1}.${ind + 1}.`}
                                            question={question}
                                        />}
                                    >
                                        <QuestionHistory slots={slots} questionID={question.id} />
                                    </Collapse.Panel>
                                })
                            }
                        </Collapse>
                    </Collapse.Panel>
                })
            }
        </Collapse>
    </Card>
}