import React from "react";

const Tile = (props: any) => {
    const {
        type,
        isMobile
    } = props;

    const tileType = {
        exam_worthy: {
            backgroundColor: "#1A804D"
        },
        medium: {
            backgroundColor: "#F5A623"
        },
        beginner: {
            backgroundColor: "#C02227"
        }
    } as any;

    return <div
        style={{
            width: 6,
            height: "100%",
            marginRight: 1,
            ...tileType[type]
        }}
    >
    </div>
}

export const ColoredProgressBar = (props: any) => {
    const { results, isMobile } = props;
    return <div
        style={{
            height: 10,
            display: "flex",
            maxWidth: "80vw",
            marginLeft: "5px"
        }}
    >
        {
            results.map((record: any, index: any) => {
                return <Tile type={record} key={index} />
            })
        }
    </div>
}