import { Card, Input } from "antd";
import React from "react";

export const FeedbackComment = (props: any) => {
    const { setFeedback, feedback } = props;
    return <Card
        className="grey-title-card"
        style={{ margin: 20 }}
        title={<b style={{ color: "#FFFFFF" }}>Komentārs par nodarbību</b>}
    >
        <Input.TextArea
            autoSize={{ maxRows: 5, minRows: 5 }}
            placeholder="Ievadiet komentāru..."
            value={feedback}
            onChange={(e: any) => {
                setFeedback(e.target.value.length > 0 ? e.target.value : null);
            }}
        />
    </Card>
}