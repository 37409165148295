import React from "react";
import { DrivingStatBadge } from "../../../components/DrivingStatBadge";
import { ReactComponent as IcPointA } from "../../../../../img/ic-a.svg";
import { ReactComponent as IcPointB } from "../../../../../img/ic-b.svg";
import { ReactComponent as IcDistance } from "../../../../../img/ic-distance.svg";
import { ReactComponent as IcSpeed } from "../../../../../img/ic-speed.svg";

export const DrivingStats = (props: any) => {

    return <div>
        <DrivingStatBadge
            Icon={IcPointA}
            text={"Uzsākts"}
            data={props.route?.start_address}
        />
        <DrivingStatBadge
            Icon={IcPointB}
            text={"Pabeigts"}
            data={props.route?.end_address}
        />
        <DrivingStatBadge
            Icon={IcDistance}
            text={"Nobrauktais attālums"}
            data={props.route?.distance ? (props.route?.distance / 1000).toFixed(1) + " km" : 0 + " km"}
        />
        {/* <DrivingStatBadge
            Icon={IcSpeed}
            text={"Ātruma pārsniegšana"}
            data={"2 gadījumi"}
        /> */}
    </div>
}