import React from "react";
import { useHistory } from "react-router-dom";

export const TabTile = (props: any) => {
    const {
        icon,
        activeIcon,
        active,
        title,
        activeKey,
        setTab
    } = props;

    return <div
        onClick={() => {
            setTab(activeKey);
        }}
        style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "14px 2px 9px",
            background: active
                ? "linear-gradient(0deg, #FFFFFF 0%, #DCF1F5 100%)"
                : "#FFFFFF",
            borderTop: active
                ? "2px solid #059DC1"
                : "2px solid #FFFFFF",
            borderRight: "1px solid #EBEEF0",
            color: active
                ? "#059DC1"
                : "#667380"
        }}
    >
        <img src={active ? activeIcon : icon} />
        <p style={{ margin: 0 }}>{title}</p>
    </div >
}