import { Card, Col, Divider, Row } from "antd";
import React, { useEffect, useState } from "react";
import { ReactComponent as IcCalendar } from "../../../../../img/ic-calendar.svg";
import { ReactComponent as IcUser } from "../../../../../img/ic-user-box.svg";
import { ReactComponent as IcLevel } from "../../../../../img/ic-level.svg";
import { ReactComponent as IcStar } from "../../../../../img/ic-star.svg";
import { InfoBadge } from "../../../components/InfoBadge";
import { EvaluationList } from "./EvaluationList";
import { MapSection } from "../MapStats/MapSection";
import { http } from "../../../../../helpers/http";

export const LessonInfoCard = (props: any) => {
    const { lesson, subjects } = props;
    const [isMobile, setIsMobile] = useState(false)

    const translation = {
        beginner: "Iesācējs",
        medium: "Vidējs",
        exam_worthy: "Tuvojas eks."
    } as any;

    useEffect(() => {
        setIsMobile(window.matchMedia("(max-width: 992px)").matches)

        window.addEventListener("resize", () => {
            setIsMobile(window.matchMedia("(max-width: 992px)").matches)
        })
    }, []);

    return <div style={{ padding: 4, backgroundColor: "#FFFFFF" }}>
        <Row>
            <Col span={24} lg={16}>
                <Row>
                    <Col span={24}>
                        <Row gutter={[14, 14]}>
                            <Col span={24} md={12}>
                                <InfoBadge
                                    Icon={IcCalendar}
                                    text={"Nodarbības datums un laiks"}
                                    data={`${lesson.lesson_date} ${lesson.time_from} - ${lesson.time_to}`}
                                />
                            </Col>
                            <Col span={24} md={12}>
                                <InfoBadge
                                    Icon={IcUser}
                                    text={"Nodarbību vērtēja"}
                                    data={lesson.instructor_name}
                                />
                            </Col>
                            <Col span={24} md={12}>
                                <InfoBadge
                                    Icon={IcLevel}
                                    text={"Nodarbības līmenis"}
                                    data={translation[lesson.grade]}
                                />
                            </Col>
                            <Col span={24} md={12}>
                                <InfoBadge
                                    Icon={IcStar}
                                    text={"Studenta reitings"}
                                    data={`${lesson.rating_improvement} punkti (${lesson.student_rating})`}
                                />
                            </Col>
                        </Row>
                    </Col>
                    {
                        lesson.feedback && lesson.feedback !== "undefined"
                        && <Col span={24}>
                            <h3><b>Instruktora komentārs par nodarbību</b></h3>
                            <Divider />
                            <p>{lesson.feedback}</p>
                            <Divider />
                        </Col>
                    }
                    <Col span={24}>
                        <EvaluationList grades={lesson.grades} subjects={subjects} />
                    </Col>
                </Row>
            </Col>
            <Col span={24} lg={8} style={{ paddingLeft: isMobile ? 0 : 36 }}>
                <MapSection isMobile={isMobile} lesson={lesson} />
            </Col>
        </Row>
    </div >
}