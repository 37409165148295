import { Col, Row } from "antd";
import React from "react";

export const InfoBadge = (props: any) => {
    const { Icon, text, data } = props;
    return <Row style={{ padding: "13px 20px 11px", backgroundColor: "#F0F2F5", borderRadius: 2 }}>
        <Col style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Icon />
        </Col>
        <Col style={{ paddingLeft: 12 }}>
            <p style={{ marginBottom: 0 }}><small>{text}</small></p>
            <b>{data}</b>
        </Col>
    </Row>
}