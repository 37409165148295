import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import icEvaluate from "../../../img/ic-nav-1.svg";
import icEvaluateActive from "../../../img/ic-nav-1-active.svg";
import icLesson from "../../../img/ic-nav-2.svg";
import icLessonActive from "../../../img/ic-nav-2-active.svg";
import icStudent from "../../../img/ic-nav-3.svg";
import icStudentActive from "../../../img/ic-nav-3-active.svg";
import icHistory from "../../../img/ic-nav-4.svg";
import icHistoryActive from "../../../img/ic-nav-4-active.svg";
import { TabTile } from "./TabTile";
import { useParams } from "react-router-dom";

export const BottomTabs = (props: any) => {
    const { activeTab } = props;
    const [tab, setTab] = useState("evaluation")

    const navLinks = [
        {
            icon: icEvaluate,
            activeIcon: icEvaluateActive,
            activeKey: "evaluation",
            title: "vērtēšana"
        },
        {
            icon: icLesson,
            activeIcon: icLessonActive,
            activeKey: "lesson",
            title: "nodarbība"
        },
        {
            icon: icStudent,
            activeIcon: icStudentActive,
            activeKey: "student",
            title: "students"
        },
        {
            icon: icHistory,
            activeIcon: icHistoryActive,
            activeKey: "history",
            title: "vēsture"
        },
    ];

    useEffect(() => {
        activeTab(tab);
    }, [tab]);

    return <Row style={{
        position: "fixed",
        backgroundColor: "#FFFFFF",
        bottom: 0,
        left: 0,
        width: "100%",
        zIndex: 1
    }}>
        {
            navLinks.map((link: any, index: any, self: any) => {
                return <Col
                    key={index}
                    span={Number((24 / self.length).toFixed(0))}
                    style={{
                        borderRight: "1px solid #EBEEF0",
                    }}
                >
                    <TabTile
                        icon={link.icon}
                        activeIcon={link.activeIcon}
                        active={link.activeKey === tab}
                        title={link.title}
                        activeKey={link.activeKey}
                        setTab={setTab}
                    />
                </Col>
            })
        }
    </Row>
}