import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { Invoice } from "./Invoice";
import { AxiosResponse } from 'axios';
import { Route, Switch, useHistory } from "react-router-dom";
import { Button, Card, Col, DatePicker, message, Row, Skeleton } from "antd";

export const InvoicesList = () => {
    const history = useHistory();
    const [invoices, setInvoices] = useState([] as any);
    const [period, setPeriod] = useState([null, null] as any);
    const [loading, setLoading] = useState(false);

    const getInvoices = (params = {}) => {
        setLoading(true);
        http.get(`invoices/get-instructor-invoices-period`, params).then((res: AxiosResponse) => {
            setLoading(false);
            setInvoices(res.data.data);
        }).catch(() => {
            message.error("Kļūda!")
            setLoading(false);
        })
    }

    useEffect(() => {
        getInvoices();
    }, []);

    useEffect(() => {
        console.log(period)
    }, [period])

    return <div>
        <Row>
            <Col>
                <p><b>Periods</b></p>
                <DatePicker.RangePicker
                    style={{ marginBottom: 20 }}
                    value={period}
                    onChange={(e) => {
                        setPeriod(e)
                    }}
                />
                {
                    period
                    && period[0]
                    && <Button
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                            getInvoices({ params: { from: period[0].format("YYYY-MM-DD"), to: period[1].format("YYYY-MM-DD") } })
                        }}
                        loading={loading}
                    >
                        Atlasīt
                    </Button>
                }
            </Col>
        </Row>
        {
            loading
                ? <Skeleton />
                : invoices?.map((invoice: any, index: number) => {
                    return <Card
                        key={index}
                        hoverable
                        onClick={() => history.push(`/invoices/${invoice?.id}`)}
                        style={{ marginBottom: 5, marginTop: 5 }}
                    >
                        <Row gutter={[10, 10]} justify="center">
                            <Col lg={6} sm={12} span={24}>
                                Rēķins: {invoice?.invoice_number}
                            </Col>
                            <Col lg={6} sm={12} span={24}>
                                {invoice?.invoice_period}
                            </Col>
                            <Col lg={6} sm={12} span={24}>
                                {invoice?.invoice_date}
                            </Col>
                            <Col lg={6} sm={12} span={24}>
                                {invoice?.total_vat.toFixed(2)} &euro;
                            </Col>
                        </Row>
                    </Card>
                })
        }
    </div>
}