import { Card, Col, Radio, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { http } from "../../../../helpers/http";

export const TheoryQuestionStage = (props: any) => {
    const { isMobile } = props;
    const [questions, setQuestions] = useState([] as any);

    const getFirstStageQuestions = () => {
        http.get(`driving-exam-first-stage-questions`).then((res: any) => {
            setQuestions(res.data.data)
        })
    };

    useEffect(() => {
        getFirstStageQuestions();
    }, []);

    useEffect(() => {
        if (questions.length > 0) {
            if (props.examTheoryQuestion === undefined) {
                props.setExamTheoryQuestion(null)
            }
        }
    }, [questions, props.examTheoryQuestion]);

    return <Card
        className="grey-title-card"
        title={<b style={{ color: "#FFFFFF" }}>1. Etaps - teorētiskās zināšanas</b>}
        style={{ marginBottom: 20 }}
    >
        <ul>
            {
                questions.map((question: any, index: any) => {
                    const status = props.examTheoryQuestion?.question_id === question.id
                        ? !!props.examTheoryQuestion.passed
                            ? "passed"
                            : "failed"
                        : -1
                    const disabled = props.examTheoryQuestion !== null && props.examTheoryQuestion?.question_id !== question.id
                    return <li key={index}>
                        <Row justify="space-between" align="bottom" style={{ flexWrap: isMobile ? "wrap" : "nowrap", marginBottom: 5 }}>
                            <Col>
                                <p style={{ margin: 0 }}>{question.content_lv}</p>
                            </Col>
                            <Col>
                                <Select
                                    style={{ width: 150 }}
                                    value={status}
                                    onChange={(e: any) => {
                                        if (e === -1) {
                                            props.setExamTheoryQuestion(null);
                                            return;
                                        }
                                        props.setExamTheoryQuestion({
                                            question_id: question.id,
                                            passed: e === "passed" ? 1 : 0
                                        })
                                    }}
                                    disabled={disabled}
                                >
                                    <Select.Option value={-1}> </Select.Option>
                                    <Select.Option value={"passed"}>Nokārtots</Select.Option>
                                    <Select.Option value={"failed"}>Nav nokārtots</Select.Option>
                                </Select>
                            </Col>
                        </Row>
                    </li>
                })
            }
        </ul>
    </Card >
}