import { Card, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { LessonsList } from "./StudentLessons/LessonsList";
import "../../../css/DayLessons.scss";
import { http } from "../../../helpers/http";
import { useParams } from "react-router-dom";

const Title = () => {
    return <Row justify="space-between">
        <b>Studenta ieplānotie braukšanas eksāmeni</b>
    </Row>
}

export const ActiveExams = (props: any) => {
    const { isMobile, category } = props;
    const { id } = useParams() as any;
    const [studentLessons, setStudentLesons] = useState([] as any);

    const getStudentNewLessons = () => {
        http.get(`get-student-exam-driving-lessons/${id}`, { params: { category: category.id } }).then((res: any) => {
            console.log(res)
            setStudentLesons(res.data.data)
        })
    };

    useEffect(() => {
        getStudentNewLessons();
    }, [])

    return studentLessons.length > 0
        ? <Card
            title={<Title />}
            bodyStyle={{
                padding: isMobile ? 0 : 24
            }}
            style={{
                margin: isMobile ? "0 0 24px" : 24
            }}
        >
            <LessonsList slots={studentLessons} updateTimeSlots={getStudentNewLessons} exam={true}/>
        </Card>
        : <></>
}