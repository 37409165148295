import React from 'react';
import './App.scss';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    matchPath
} from "react-router-dom";

import { Button, Card, Form, Input, Layout, Menu } from 'antd';
import { CalendarPage } from './pages/Planner/CalendarPage';
import { http } from './helpers/http';
import { DashboardPage } from './pages/DashboardPage';
import { HistoryPage } from './pages/HistoryPage';
import Sider from 'antd/lib/layout/Sider';
import buildJson from "./build.json";
import { StudentEvalForm } from './pages/StudentStats/EvaluationForm/StudentEvalForm';
import { StudentStatsView } from './pages/StudentStats/StudentStatsView/StudentStatsView';
import { StatsSection } from './pages/StudentStats/StudentStatsView/StatsSection';
import { InvoicesLayout } from './pages/invoices/InvoicesLayout';
import { ExchangeCarsLayout } from './pages/ExhangeCars/ExchangeCarsLayout';
import { ExchangedReminder } from './pages/ExhangeCars/ExchangedReminder';

class App extends React.Component<{}, {
    login: boolean,
    loaded: boolean,
    windowHeight: number,
    self: any,
    hasExchangedVehicle: boolean
}> {
    constructor(props: {}) {
        super(props);

        this.state = {
            windowHeight: window.innerHeight,
            login: false,
            loaded: false,
            self: {},
            hasExchangedVehicle: false
        };
    }

    instructorsTesters = [5, 16, 48, 66, 90, 15, 4, 89, 26, 2];

    handleWindowResize = () => {
        this.setState({ windowHeight: window.innerHeight });
    }

    checkCache = async () => {
        if (Number(process.env.REACT_APP_DEV) === 1) return;
        http.get('build-time?app=instructor').then((response: any) => {
            console.log(response.data.build_time, buildJson.buildTime)
            if (!(response.data.build_time === buildJson.buildTime)) {
                window.location.href = window.location.href;
                console.log("Clearing cache")
                window.location.reload();
            }
        })
    }

    fetchSelf = () => {
        http.get(`self`).then((response: any) => {
            console.log(response)
            const hasExchangedVehicle = response.data.data?.services?.some((service: any) => service.exchange_id)
            this.setState({ self: response.data.data, hasExchangedVehicle })
        })
    }

    async componentDidMount() {
        this.checkCache();
        window.addEventListener('resize', this.handleWindowResize);
        const sp = new URLSearchParams(window.location.search);
        const savedToken = sp.get("bt");
        const urlAdmin = sp.get("admin");
        if (savedToken != null) {
            if (urlAdmin != null) {
                sessionStorage.setItem("admin", urlAdmin);
            }
            sessionStorage.setItem("bearer_token", savedToken);
            window.location.href = "/";
        }

        const token = sessionStorage.getItem("bearer_token");
        const admin = sessionStorage.getItem("admin");
        if (admin != null) {
            http.defaults.params = { admin };
        }
        if (token == null) {
            this.setState({ login: true, loaded: true });
        } else {
            http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            this.fetchSelf();
            this.setState({ loaded: true });
        }
    }

    componentDidUpdate() {
        console.log(this.state)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    menuLinks = [
        { to: "/dashboard", title: "Kopsavilkums" },
        { to: "/calendar", title: "Nodarbību kalendārs" },
        { to: "/history", title: "Nodarbību vēsture" },
        { to: "/invoices", title: "Rēķini" }
    ];

    logout = (e: any) => {
        e.preventDefault();
        sessionStorage.clear();
        window.location.href = "/";
    }

    render() {
        const normalLayout = (
            <Layout style={{ minHeight: "100vh", overflow: "scroll" }}>
                <Sider breakpoint={'md'} collapsedWidth={0} style={{ position: "fixed", zIndex: 100, height: "100vh" }}>
                    <div className="logo">Einšteins</div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "calc(100vh - 70px)" }}>
                        <Route path="/" render={({ location }) => {
                            const menuLinks = this.menuLinks;

                            const selectedKeys = menuLinks
                                .map((link, index) => matchPath(location.pathname, { path: link.to }) ? String(index + 1) : null)
                                .filter((value: any) => typeof value === 'string');

                            return (
                                <Menu theme="dark" mode="inline" selectedKeys={selectedKeys as any}>
                                    {menuLinks.map((link, index) =>
                                        <Menu.Item key={String(index + 1)}>
                                            <Link to={link.to}>{link.title}</Link>
                                        </Menu.Item>
                                    )}
                                    <Menu.Item>
                                        <a href="/#logout" onClick={this.logout} >Atslēgties</a>
                                    </Menu.Item>
                                </Menu>
                            );
                        }}>
                        </Route>
                        <div style={{ padding: "20px 20px 65px" }}>
                            <b style={{ color: "#FFF", textAlign: "center" }}>{this.state.self?.first_name} {this.state.self?.last_name}</b>
                        </div>
                    </div>
                </Sider>
                <Layout style={{ display: "flex", flexDirection: "column", paddingTop: this.state.hasExchangedVehicle ? 86 : 0 }}>
                    {
                        this.state.hasExchangedVehicle
                        && <ExchangedReminder />
                    }
                    <Switch>
                        <Route path="/student-stats/:id">
                            <StatsSection />
                        </Route>
                        <Route path="/dashboard">
                            <DashboardPage self={this.state.self} />
                        </Route>
                        <Route path="/calendar">
                            <CalendarPage />
                        </Route>
                        <Route path="/history">
                            <HistoryPage />
                        </Route>
                        <Route path="/invoices">
                            <InvoicesLayout />
                        </Route>
                        <Route path={"/valuate-student/:studentID/:id/exam"}>
                            <StudentEvalForm self={this.state.self} exam />
                        </Route>
                        <Route path={"/valuate-student/:studentID/:id"}>
                            <StudentEvalForm self={this.state.self} />
                        </Route>
                        <Route path={"/exchange-cars"}>
                            <ExchangeCarsLayout self={this.state.self} refresh={this.fetchSelf} />
                        </Route>
                        <Route exact path="/">
                            <Redirect to="/dashboard" />
                        </Route>
                    </Switch>
                </Layout>
            </Layout>
        );

        const onLogin = async ({ username, password }: any) => {
            try {
                const result = await http.post("login", { username, password });
                const bearer_token = result.data.data.api_token;
                if (bearer_token != null) {
                    sessionStorage.setItem("bearer_token", bearer_token);
                    window.location.href = "/";
                } else {
                    throw new Error();
                }
            } catch (e) {
                console.error("Login failed");
            }
        };

        const loginLayout = (
            <Layout className="login-layout">
                <img src="/image-login.jpg" className="login-layout__bg" />
                <Card title="Pieslēgties instruktora portālam" style={{ width: 400, margin: "20vh auto" }}>
                    <Form name="login_form" onFinish={onLogin} layout="vertical">
                        <Form.Item name="username" label="Lietotājs">
                            <Input />
                        </Form.Item>
                        <Form.Item name="password" label="Parole">
                            <Input type="password" />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" type="primary">Ienākt</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Layout>
        );

        let out = null;
        if (this.state.loaded) {
            out = this.state.login ? loginLayout : normalLayout;
        }

        return <Router>
            <div className="App">
                {out}
            </div>
        </Router>
    };
}

export default App;
