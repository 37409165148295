import { Radio, Row, Select } from "antd";
import React, { useState } from "react";
import IcExpandAll from "../../../../img/ic-expand-all.svg";
import IcCollapseAll from "../../../../img/ic-collapse-all.svg";

export const EvalTestFilter = (props: any) => {
    const {
        isMobile,
        setExpand,
        themes,
        setShowThemes
    } = props;

    return <Row justify="space-between" style={{ flexWrap: "nowrap" }}>
        <Select
            style={{ width: isMobile ? 200 : 250 }}
            onChange={(e: any) => {
                if (e === -1) {
                    setShowThemes(themes);
                    return;
                }
                setShowThemes([themes.find((el: any) => el.theme_lv === e)]);
            }}
        >
            <Select.Option value={-1}>Visas tēmas</Select.Option>
            {
                themes.map((theme: any, index: any) => {
                    return <Select.Option key={index} value={theme.theme_lv}>{theme.theme_lv}</Select.Option>
                })
            }
        </Select>
        <Radio.Group
            className="ghost-radio-buttons"
            buttonStyle="solid"
            onChange={(e: any) => {
                setExpand(e.target.value)
            }}
        >
            <Radio.Button
                style={{
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5
                }}
                value={true}
            >
                <img src={IcExpandAll} />
            </Radio.Button>
            <Radio.Button
                style={{
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5
                }}
                value={false}
            >
                <img src={IcCollapseAll} />
            </Radio.Button>
        </Radio.Group>
    </Row>
}