import { Button } from "antd";
import React, { useState } from "react";

export const EvalHeader = (props: any) => {
    const {
        isMobile,
        setActiveTab,
        student
    } = props;
    const [tab, setTab] = useState("evaluation");

    const changeTab = () => {
        const newTab = tab === "evaluation" ? "history" : "evaluation"
        setActiveTab(newTab);
        setTab(newTab);
    }

    return <div
        style={{
            height: 42,
            width: "100%",
            backgroundColor: "#1e1e24",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        }}
    >
        <h3 style={{ color: "#FFFFFF", paddingLeft: 52, margin: 0 }}>{student}</h3>
        {
            !isMobile
            && <Button
                type="text"
                onClick={() => {
                    changeTab();
                }}
            >
                <h4 style={{ color: "#FFFFFF" }}>
                    {
                        tab === "evaluation"
                            ? "Nodarbību vēsture"
                            : "Vērtēšana"
                    }
                </h4>
            </Button>
        }
    </div>
}