import { Button, Col, Input, Row } from "antd";
import React from "react";

export const PointInput = (props: any) => {
    const {
        removeMapPoint,
        point,
        index,
        updatePoint
    } = props;

    return <div style={{ marginBottom: 24 }}>
        <Row align="middle" style={{ marginBottom: 15 }}>
            <div style={{
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                width: 32,
                height: 32,
                backgroundColor: "#FA3A00",
                color: "#FFFFFF",
                marginRight: 10
            }}>
                {Number(index + 1)}
            </div>
            <Col>
                <b>{point.formated_address}</b><br />
                <small style={{ display: "block" }}>
                    Laiks: {point.action_time}
                    {/* Ātrums: {point.speed} */}
                </small><br />
                {/* <small>({point.lat} {point.lng})</small> */}
            </Col>
            <Button
                type="text"
                style={{
                    margin: "auto 0 0 auto",
                    fontSize: 11.2,
                    padding: 0,
                    height: "auto"
                }}
                onClick={() => removeMapPoint(index)}
            >
                Dzēst punktu
            </Button>
        </Row>
        <Input.TextArea
            value={point.comment}
            autoSize={{ minRows: 3, maxRows: 3 }}
            placeholder="Ievadiet komentāru..."
            onChange={(e: any) => {
                updatePoint(index, e.target.value)
            }}
        />
    </div>
}