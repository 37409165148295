import { Col, Divider, Row, Tag } from "antd";
import React, { useEffect, useState } from "react";
import "../../../../../css/StudentEvaluation.scss";
import { http } from "../../../../../helpers/http";

export const TheoryExamQuestionList = (props: any) => {
    const { lesson } = props;
    const [result, setResult] = useState(null as any);

    const fetchTheoryQuestionResult = () => {
        http.get(`get-lesson-first-stage-answers/${lesson}`).then((res: any) => {
            console.log(res)
            setResult(res.data.data[0])
        })
    }

    useEffect(() => {
        fetchTheoryQuestionResult();
    }, [])

    return result
        ? <div>
            <h3><b>Teorētiskais jautājums</b></h3>
            <Row style={{ flexWrap: "nowrap" }}>
                <Col>
                    <p style={{ margin: 0 }}>
                        <b>{result.question.content_lv}</b>
                    </p>
                </Col>
                <Col>
                    <Tag style={{ fontSize: 14, marginLeft: 10 }} color={result.passed ? "green" : "red"}>{result.passed ? "Nokārtots" : "Nav nokārtots"}</Tag>
                </Col>
            </Row>
            <Divider />
        </div>
        : <></>
}