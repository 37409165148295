import { Card, Layout } from "antd"
import React from "react"
import { Route, Switch } from "react-router-dom"
import { Invoice } from "./Invoice"
import { InvoicesList } from "./InvoicesList"
import { StudentInvoicesList } from "./StudentInvoicesList"

export const InvoicesLayout = () => {

    return <Layout className="main-content">
        <div
            style={{
                backgroundColor: "#FFFFFF",
                padding: "10px 0 10px 46px"
            }}
        >
            <b>Rēķini</b>
        </div>
        <div style={{ margin: 20 }}>
            <Card>
                <Switch>
                    <Route exact path={"/invoices"}>
                        <InvoicesList />
                    </Route>
                    <Route path={"/invoices/:id"}>
                        <StudentInvoicesList />
                    </Route>
                </Switch>
            </Card>
        </div>
    </Layout>
}