import { Button, Card, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "../../../css/DayLessons.scss";
import { http } from "../../../helpers/http";
import { NotApprovedSlotList } from "./NotApprovedSlotList";
import { NotApprovedSlot } from "./NotAprrovedSlot";

const Title = ({ count }: any) => {
    return <Row justify="space-between">
        <b>Nepieciešams apstiprinājums</b>
        {count}
    </Row>
}

export const NotApprovedCard = (props: any) => {
    const { updateTimeSlots } = props;
    const [pendingLessons, setPendingLessons] = useState([] as any);

    const fetchPendingLessons = () => {
        http.get(`lessons/pending`).then((res: any) => {
            console.log(res)
            setPendingLessons(res.data.data)
        })
    };

    useEffect(() => {
        fetchPendingLessons();
    }, [])

    return <Card
        title={<Title count={`(${pendingLessons?.length})`} />}
        style={{ marginBottom: 40, maxHeight: "80vh", overflow: "scroll" }}
    >
        <NotApprovedSlotList
            slots={pendingLessons}
            updateTimeSlots={() => {
                fetchPendingLessons();
                updateTimeSlots();
            }}
        />
    </Card>
}