import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { ConfigProvider } from 'antd';
import lv_LV from 'antd/lib/locale/lv_LV';

ReactDOM.render(
  <ConfigProvider locale={lv_LV}>
    <App />
  </ConfigProvider>,
  document.getElementById('root')
);
