import { Button, Card, Col, Collapse, Radio, Row, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { EvalAnswerHelper } from "../../components/EvalAnswerHelper";
import { EvalFormQuestion } from "../EvalFormQuestion";
import { EvalCardHeader } from "./EvalCardHeader";
import { EvalTestFilter } from "./EvalTestFilter";
import IcArrowDown from "../../../../img/ic-arrow-down.svg";
import { TheoryQuestionStage } from "./TheoryQuestionStage";
import { SpecialManeuverForm } from "./SpecialManeuverForm";

const ThemeHeader = (props: any) => {
    const { index, theme, isMobile, expandedKeys, processIntersections } = props;
    const [intersected, setIntersected] = useState({
        name: theme.theme_lv,
        visible: false
    })

    const ref = useRef(null) as any;

    return <Row ref={ref} justify="space-between" style={{ flexWrap: "nowrap" }}>
        <div>
            {theme.ind + ". " + theme.theme_lv}
            {
                !isMobile
                &&
                <img
                    style={{
                        marginLeft: 10,
                        transform: !expandedKeys.some((el: any) => el === theme)
                            ? "rotate(180deg)"
                            : "rotate(0)"
                    }}
                    src={IcArrowDown}
                />
            }
        </div>
        {
            !isMobile && expandedKeys.some((el: any) => el === theme.theme_lv)
                ? <EvalAnswerHelper isMobile={isMobile} />
                : <></>
        }
    </Row>
}

export const EvalCardLayout = (props: any) => {
    const {
        isMobile,
        answers,
        setAnswers,
        themes,
        returnStudentLvl,
        studentLVL,
        subjectHistory,
        exam,
        setFigures,
        figures,
        setExamTheoryQuestion,
        examTheoryQuestion,
        firstRender
    } = props;

    const [expandedKeys, setExpandedKeys] = useState([]);
    const [showThemes, setShowThemes] = useState(themes);
    const [themesIntersected, setThemesIntersected] = useState([] as any);
    const [showFixedTheme, setShowFixedTheme] = useState("");

    const setExpandAll = (open: boolean) => {
        const allThemes = themes?.map((theme: any) => {
            return theme.theme_lv
        })
        setExpandedKeys(open ? allThemes : []);
    };

    // const processIntersections = (name: string, intersecting: boolean) => {
    //     console.log(name, intersecting)
    //     // console.log(themesIntersected.find((el: any) => el.name === name))

    //     setThemesIntersected((prev: any) => {
    //         const copyPrev = JSON.parse(JSON.stringify(prev));
    //         const newIntersectionableList = copyPrev.map((theme: any) => {
    //             if (theme.name === name) {
    //                 return {
    //                     ...theme,
    //                     visible: intersecting,
    //                     last: theme.visible && !intersecting
    //                 }
    //             }
    //             return {
    //                 ...theme,
    //                 last: false
    //             }
    //         })
    //         return newIntersectionableList;
    //     });

    // };

    useEffect(() => {
        // console.log(expandedKeys)
        // setThemesIntersected((prev: any) => {
        //     const copyPrev = JSON.parse(JSON.stringify(prev));
        //     const newIntersectionableList = copyPrev.map((theme: any) => {
        //         if (expandedKeys.some((el: any) => el === theme.name)) {
        //             return {
        //                 ...theme,
        //                 active: true,
        //                 visible: true,
        //             }
        //         } else {
        //             return {
        //                 ...theme,
        //                 active: false,
        //                 visible: false
        //             }
        //         }
        //     })
        //     return newIntersectionableList;
        // });
    }, [expandedKeys]);

    useEffect(() => {
        setShowThemes(themes)
        // setThemesIntersected((prev: any) => {
        //     return themes.map((theme: any) => {
        //         return {
        //             name: theme.theme_lv,
        //             active: false
        //         }
        //     })
        // })
    }, [themes]);

    return <div
        onTouchEnd={() => setShowFixedTheme("")}
    >
        {
            isMobile
            && <div
                style={{
                    backgroundColor: "#001529",
                    width: "100%",
                    height: 42,
                    position: "fixed",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#FFFFFF",
                    top: 0,
                    left: 0,
                    zIndex: 2,
                    opacity: showFixedTheme.length > 0 ? 1 : 1
                }}
            >
                {showFixedTheme}
            </div>
        }
        {isMobile && <EvalAnswerHelper isMobile={isMobile} />}
        <Card
            style={{ margin: isMobile ? 0 : 20, backgroundColor: isMobile ? "#F0F2F5" : "#FFFFFF" }}
            bodyStyle={{ padding: `24px ${isMobile ? "10px" : "24px"}` }}
            title={isMobile
                ? false
                : <EvalCardHeader
                    isMobile={isMobile}
                    returnStudentLvl={returnStudentLvl}
                    studentLVL={studentLVL}
                    exam={exam}
                />}
        >
            {
                (!!props.student?.only_error_driving && !exam)
                && <h4 style={{ fontSize: 16, color: "red", textAlign: "center" }}>Izbraukt iepriekšējā eksāmena kļūdas!</h4>
            }
            {
                exam
                && <div>
                    <TheoryQuestionStage
                        isMobile={isMobile}
                        setExamTheoryQuestion={setExamTheoryQuestion}
                        examTheoryQuestion={examTheoryQuestion}
                        firstRender={firstRender}
                    />
                    <SpecialManeuverForm
                        figures={figures}
                        firstRender={firstRender}
                        setFigures={setFigures}
                    />
                </div>
            }
            <EvalTestFilter
                isMobile={isMobile}
                setExpand={setExpandAll}
                themes={themes}
                setShowThemes={(themes: Array<any>) => setShowThemes(themes)}
            />
            <Col
                span={24}
                style={{ marginTop: 24 }}
            >
                <Collapse
                    activeKey={expandedKeys}
                    className="valuate-questions-collapse"
                    expandIconPosition={"right"}
                    onChange={(e: any) => {
                        setExpandedKeys(e)
                    }}
                >
                    {
                        themes?.filter((item: any) => {
                            return showThemes.some((el: any) => el.theme_lv === item.theme_lv)
                        }).map((theme: any, index: any) => {
                            return <Collapse.Panel
                                showArrow={isMobile}
                                header={
                                    <ThemeHeader
                                        index={index}
                                        theme={theme}
                                        isMobile={isMobile}
                                        expandedKeys={expandedKeys}
                                    // processIntersections={(name: string, intersecting: boolean) => processIntersections(name, intersecting)}
                                    />
                                }
                                key={theme.theme_lv}
                                id={`questions_category_${index}`}
                                forceRender
                            >
                                <div
                                    onTouchStart={() => setShowFixedTheme(theme.theme_lv)}

                                    onTouchEnd={(e: any) => e.stopPropagation()}
                                >
                                    <EvalFormQuestion
                                        key={index}
                                        parentInd={theme.ind}
                                        isMobile={isMobile}
                                        category={theme.theme_lv}
                                        answers={answers}
                                        setAnswers={setAnswers}
                                        subjects={theme.subjects}
                                        subjectHistory={subjectHistory}
                                    />
                                </div>
                            </Collapse.Panel>
                        })
                    }
                </Collapse>
            </Col>
        </Card>
    </div>
}