import React from "react";

export const ColoredBadge = (props: any) => {

    const {
        type,
        number
    } = props;

    const active = number > 0;

    const translation = {
        exam_worthy: "L",
        medium: "P",
        beginner: "NP",
        none: "NV"
    } as any;

    const typeStyles = {
        exam_worthy: {
            backgroundColor: active ? "#1A804D" : "rgba(26, 128, 77, 0.1)",
            color: active ? "#FFFFFF" : "#1A804D"
        },
        medium: {
            backgroundColor: active ? "#E6812E" : "rgba(230, 129, 46, 0.1)",
            color: active ? "#FFFFFF" : "#E6812E"
        },
        beginner: {
            backgroundColor: active ? "#C02227" : "rgba(192, 34, 39, 0.1)",
            color: active ? "#FFFFFF" : "#C02227"
        },
        none: {
            backgroundColor: active ? "#525C66" : "rgba(82, 92, 102, 0.1)",
            color: active ? "#FFFFFF" : "#525C66"
        }
    } as any;

    return <div
        style={{
            ...{
                width: "50px",
                height: "22px",
                borderRadius: 4,
                marginLeft: 6,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 11,
                fontWeight: "bold"
            }, ...typeStyles[type]
        }}
    >
        {translation[type]}:{number}
    </div>
}