import { Button, Row } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as GoBackArrow } from '../img/ic-goback.svg';

export const Header = (props: any) => {
    const history = useHistory();

    return (
        <Row className="custom-header">
            <Button
                className="go-back-btn"
                style={{ fontWeight: "bold", marginRight: 29 }}
                icon={<GoBackArrow />}
                onClick={() => history.goBack()}
            >
            </Button>
            <b style={{ lineHeight: "30px" }}>Atpakaļ</b>
        </Row>
    )
}