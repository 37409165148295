import { Row, Col, Button, Modal, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { AxiosResponse } from "axios";

type ServiceCardProps = {
    service: any;
    refresh: () => void;
}

export const ServiceCard = ({ service, refresh }: ServiceCardProps) => {
    const [visible, setVisible] = useState(false);
    const [exchangeVehicles, setExchangeVehicles] = useState([] as any);
    const [vehicle, setVehicle] = useState({} as any);

    const getExchangeVehicles = () => {
        http.get(`exchange-cars`, {
            params: {
                no_paginate: 1,
                filters: {
                    free: 1,
                    active: 1
                }
            }
        }).then((res: AxiosResponse) => {
            setExchangeVehicles(res.data.data)
        })
    }

    const resetModal = () => {
        setVisible(false);
        setVehicle({});
    }

    const setNewVehicle = () => {
        http.post(`take-exchange-car/${vehicle.id}/${service.id}`).then((res: AxiosResponse) => {
            refresh();
            resetModal();
            message.success("Transportlīdzeklis ir nomainīts");
        }).catch(() => {
            message.error("Kļūda!");
        })
    }

    const unsetExchangeVehicle = () => {
        Modal.confirm({
            title: "Atcelt maiņas transportlīdzekli",
            content: "Vai tiešām atcelt maiņas transportlīdzekli?",
            onOk: () => {
                http.post(`return-exchange-car/${service.exchange_id}`).then((res: AxiosResponse) => {
                    refresh();
                    message.success("Maiņas transportlīdzeklis noņemts");
                }).catch(() => {
                    message.error("Kļūda!");
                })
            }
        })
    }

    const columns = [
        {
            title: "Marka",
            dataIndex: "vehicle",
            key: "vehicle"
        },
        {
            title: "VRN",
            dataIndex: "licence_plate",
            key: "licence_plate"
        },
        {
            title: "Pārnesumkārba",
            dataIndex: "transmission",
            key: "transmission",
            render: (transmission: any) => {
                return transmission === "auto"
                    ? "Automātiskā"
                    : "Manuālā"
            }
        },
        {
            title: "Pilsēta",
            dataIndex: "city",
            key: "city"
        }
    ]

    useEffect(() => {
        if (visible) {
            getExchangeVehicles();
        }
    }, [visible])

    return <Row
        style={{ borderBottom: "1px solid #F0F0F0", marginBottom: 10, padding: "15px 0" }}
        gutter={[20, 10]}
        key={service.id}
    >
        <Modal
            visible={visible}
            onCancel={resetModal}
            footer={<Button
                type="primary"
                disabled={Object.keys(vehicle).length === 0}
                onClick={setNewVehicle}
            >
                Apstiprināt
            </Button>}
        >
            <Table
                rowKey={record => record.id}
                columns={columns}
                dataSource={exchangeVehicles}
                size="small"
                pagination={false}
                // onRow={(record, rowIndex) => {
                //     return {
                //         onClick: event => {
                //             setVehicle(record);
                //         }
                //     };
                // }}
                rowSelection={{
                    type: "radio",
                    onChange: (selectedRowKeys, selectedRows) => {
                        setVehicle(selectedRows[0])
                    },
                }}
            />
        </Modal>
        <Col span={12} sm={6}>
            <p>Kategorija:</p>
            <b>{service.category.value}</b>
        </Col>
        <Col span={12} sm={6}>
            <p>Transportlīdzeklis:</p>
            <b>{service.vehicle_and_plate}</b>
        </Col>
        <Col span={12} sm={6}>
            <p>Pārnesumkārba:</p>
            <b>{service.transmission === "auto" ? "Automātiskā" : "Manuālā"}</b>
        </Col>
        <Col span={12} sm={6}>
            <p>Maiņas auto:</p>
            <b>{service.exchange_id ? "Jā" : "Nē"}</b>
            {
                service.exchange_id
                    ? <Button
                        style={{ marginLeft: 10 }}
                        onClick={unsetExchangeVehicle}
                    >
                        Atcelt
                    </Button>
                    : <Button
                        style={{ marginLeft: 10 }}
                        onClick={() => setVisible(true)}
                    >
                        Uzstādīt
                    </Button>
            }
        </Col>
    </Row>
}