import { Row, Col, Button } from "antd"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { http } from "../../../../helpers/http";

export const InfoSection = (props: any) => {
    const { isMobile, student, counters } = props;
    const { studentID } = useParams() as any;

    return <Row gutter={24} justify="space-between">
        <Col style={{ width: "100%" }}>
            <Row align="middle" style={{
                flexDirection: "column"
            }}>
                <div style={{
                    width: "100%",
                    marginBottom: 40
                }}>
                    <h2 style={{
                        textAlign: "left",
                        marginBottom: 20
                    }}>
                        {student.first_name} {student.last_name} {<br />}
                    </h2>
                    <small>Tālrunis</small>
                    <p><b>{student.phone_number}</b></p>
                    <small>E-pasta adrese</small>
                    <p><b>{student.email}</b></p>
                    <small>Apmācības valoda</small>
                    <p><b>{student.language}</b></p>
                    <Row
                        gutter={24}
                        justify={"center"}
                        style={{
                            borderTop: "1px solid #CED4D8",
                            borderBottom: "1px solid #CED4D8",
                            padding: "10px 0"
                        }}
                    >
                        <Col style={{ display: "flex", flexDirection: "column" }}>
                            <small>Apmeklētas</small>
                            <div ><b style={{ fontSize: 17, display: "block", textAlign: "center" }}>
                                {counters?.done}
                            </b></div>
                        </Col>
                        <Col style={{ display: "flex", flexDirection: "column" }}>
                            <small>Ieplānotas</small>
                            <div><b style={{ fontSize: 17, display: "block", textAlign: "center" }}>
                                {counters?.new}
                            </b></div>
                        </Col>
                        <Col style={{ display: "flex", flexDirection: "column" }}>
                            <small>Neapmeklētas</small>
                            <div><b style={{ fontSize: 17, display: "block", textAlign: "center" }}>
                                {counters?.student_didnt_come}
                            </b></div>
                        </Col>
                    </Row>
                </div>
            </Row>
        </Col >
    </Row >
}