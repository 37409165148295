import { Button, Card, Col, Divider, Row } from "antd";
import { FileOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { ReactComponent as IcCalendar } from "../../../../../img/ic-calendar.svg";
import { ReactComponent as IcUser } from "../../../../../img/ic-user-box.svg";
import { ReactComponent as IcLevel } from "../../../../../img/ic-level.svg";
import { ReactComponent as IcStar } from "../../../../../img/ic-star.svg";
import { InfoBadge } from "../../../components/InfoBadge";
import { EvaluationList } from "./EvaluationList";
import { MapSection } from "../MapStats/MapSection";
import { TheoryExamQuestionList } from "./TheoryExamQuestionList";
import { SpecialManeuverList } from "./SpecialManeuverList";

export const ExamInfoCard = (props: any) => {
    const { lesson, subjects } = props;
    const [isMobile, setIsMobile] = useState(false)

    const translation = {
        beginner: "Iesācējs",
        medium: "Vidējs",
        exam_worthy: "Tuvojas eks."
    } as any;
    const attachment1 = lesson.attachment && !lesson?.attachment?.includes("noimage") && lesson.attachment;
    const attachment2 = lesson.attachment2 && !lesson?.attachment2?.includes("noimage") && lesson.attachment2;
    const baseUrl = process.env.REACT_APP_API_URL + "storage/student_lesson_reports/"

    useEffect(() => {
        setIsMobile(window.matchMedia("(max-width: 992px)").matches)

        window.addEventListener("resize", () => {
            setIsMobile(window.matchMedia("(max-width: 992px)").matches)
        })
    }, []);

    return <div style={{ padding: 4, backgroundColor: "#FFFFFF" }}>
        <Row>
            <Col span={24} lg={16}>
                <Row>
                    <Col span={24}>
                        <Row gutter={[14, 14]}>
                            <Col span={24} md={12}>
                                <InfoBadge
                                    Icon={IcCalendar}
                                    text={"Eksāmena datums un laiks"}
                                    data={`${lesson.lesson_date} ${lesson.time_from} - ${lesson.time_to}`}
                                />
                            </Col>
                            <Col span={24} md={12}>
                                <InfoBadge
                                    Icon={IcUser}
                                    text={"Eksāmenu vērtēja"}
                                    data={lesson.instructor_name}
                                />
                            </Col>
                            {
                                lesson.exam_giver !== undefined
                                && <Col span={24} md={12}>
                                    <InfoBadge
                                        Icon={IcUser}
                                        text={"Uz eksāmenu novirzīja"}
                                        data={lesson.exam_giver?.admin ?? lesson.exam_giver?.instructor ?? "Nav informācijas"}
                                    />
                                </Col>
                            }
                        </Row>
                    </Col>
                    {
                        attachment1 || attachment2
                            ? <Col span={24}>
                                <h3><b>Pievienotie faili</b></h3>
                                {attachment1 && <a style={{ marginLeft: 10 }} href={baseUrl + attachment1} target="_blank" download><Button><FileOutlined />Fails 1</Button></a>}
                                {attachment2 && <a style={{ marginLeft: 10 }} href={baseUrl + attachment2} target="_blank" download><Button><FileOutlined />Fails 2</Button></a>}
                                <Divider />
                            </Col>
                            : <></>
                    }
                    {
                        lesson.feedback && lesson.feedback !== "undefined"
                        && <Col span={24}>
                            <h3><b>Instruktora komentārs par eksāmenu</b></h3>
                            <Divider />
                            <p>{lesson.feedback}</p>
                            <Divider />
                        </Col>
                    }
                    <Col span={24}>
                        <TheoryExamQuestionList
                            lesson={lesson.student_lesson_id}
                        />
                    </Col>
                    <Col span={24}>
                        <SpecialManeuverList
                            lesson={lesson.student_lesson_id}
                        />
                    </Col>
                    <Col span={24}>
                        <EvaluationList grades={lesson.grades} subjects={subjects} />
                    </Col>
                </Row>
            </Col>
            <Col span={24} lg={8} style={{ paddingLeft: isMobile ? 0 : 36 }}>
                <MapSection isMobile={isMobile} lesson={lesson} />
            </Col>
        </Row>
    </div >
}