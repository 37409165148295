import { Row } from "antd";
import React from "react";

export const DrivingStatBadge = (props: any) => {
    const {
        Icon,
        text,
        data
    } = props;

    return <Row style={{ marginBottom: 10 }}>
        <Icon />
        <p style={{ margin: 0 }}>{text}: <b>{data}</b></p>
    </Row>
}